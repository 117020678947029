import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CameraIcon from '../../../../assets/svg/CameraIcon.svg';
import AddIcon from '../../../../assets/svg/AddIcon.svg';
import Button from '@material-ui/core/Button';
import { Alert } from '../../../../shared/components/Alert/index';
import { useDropzone } from 'react-dropzone';
import Close from '@material-ui/icons/CancelTwoTone';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cameraWrapper: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
    height: '200px',
    background: '#B8B7B7',
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    width: '100%',
    height: '1px',
    background: '#B1B1B1',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowX: 'scroll',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  btnStyles: {
    background: '#007aff',
    textTransform: 'none',
    color: '#fff',
    fontFamily: 'SF Compact Display Bold',
    fontSize: '20px',
  },
}));

const SecondStepForm = (props) => {
  const classes = useStyles();
  // Initially there are only two cards
  const [cards, setCards] = useState([
    {
      frontCard: 'Front of Card',
      frontImageData: '',
      backCard: 'Back of Card',
      backImageData: '',
    },
  ]);
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);
  // Card Side
  const [cardSide, setCardSide] = useState('');
  // selected Card
  const [selectedCard, setSelectedCard] = useState(null);
  // Error
  const [showError, setShowError] = useState(false);
  // Show success message
  const [showSuccess, setShowSuccess] = useState(false);
  // Attached Image
  const [attachedImage, setAttachedImage] = useState('');

  // Video frame constraints
  const [videoConstraints, setVideoConstraints] = useState({
    width: 500,
    height: 250,
    facingMode: 'user',
  });

  // File attachment handler
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles.find((f) => f);
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        let image = reader.result;
        // Setting the imageData for the API CALL
        props.setImageData((images) => {
          let tempImages = [...images];
          try {
            if (cardSide === 'front') tempImages[selectedCard].front = image;
            else if (cardSide === 'back') tempImages[selectedCard].back = image;
          } catch (err) {
            tempImages.push({});
            if (cardSide === 'front') tempImages[selectedCard].front = image;
            else if (cardSide === 'back') tempImages[selectedCard].back = image;
          }
          return tempImages;
        });

        // Setting the cards data
        setCards((cards) => {
          let tempCards = [...cards];
          if (cardSide === 'front')
            tempCards[selectedCard].frontImageData = image;
          else if (cardSide === 'back')
            tempCards[selectedCard].backImageData = image;
          return tempCards;
        });

        // Showing the success message
        setShowSuccess(true);
      };
    },
    [cardSide, selectedCard],
  );

  // Dropzone props
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const addCard = () => {
    let flag = true;
    cards.forEach((card) => {
      if (card.frontImageData === '' || card.backImageData === '') {
        setShowError(true);
        flag = false;
      }
    });
    if (flag)
      setCards((cards) => {
        let tempCards = [...cards];
        tempCards.push({
          frontCard: 'Front of Card',
          frontImageData: '',
          backCard: 'Back of Card',
          backImageData: '',
        });
        return tempCards;
      });
  };

  const modalCloser = () => {
    setIsModelOpen(false);
    setAttachedImage('');
  };

  const cameraModalOpener = () => {
    setIsCameraModalOpen(true);
  };

  const cameraModalCloser = () => {
    setIsCameraModalOpen(false);
  };

  const openCameraHandler = () => {
    // Closing the options modal
    setIsModelOpen(false);
    // Opening the camera modal - webcam modal
    setIsCameraModalOpen(true);
  };

  const handleFinish = () => {
    let flag = true;
    // Checking if the added card contains the images or not
    cards.forEach((card) => {
      if (card.frontImageData === '' || card.backImageData === '') {
        setShowError(true);
        flag = false;
      }
    });
    // Only call the finish if the cards contains the images
    if (flag) {
      props.changeStep(2);
    }
  };

  const deleteImage = (event, index, _cardSide) => {
    event.persist();
    event.stopPropagation();

    setCards((cards) => {
      let tempCards = [...cards];
      if (_cardSide === 'front') {
        tempCards[index].frontImageData = '';
      } else if (_cardSide === 'back') tempCards[index].backImageData = '';

      return tempCards;
    });

    props.setImageData((images) => {
      let tempImages = [...props.ImageData];
      if (_cardSide === 'front') tempImages[index].front = '';
      else if (_cardSide === 'back') tempImages[index].back = '';

      return tempImages;
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccess(false);
    setShowError(false);
  };

  const fileHandler = (event) => {
    const file = event.target.files[0];

    let reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = reader.result;

      // Setting the imageData for the API CALL
      props.setImageData((images) => {
        let tempImages = [...images];
        try {
          if (cardSide === 'front') tempImages[selectedCard].front = image;
          else if (cardSide === 'back') tempImages[selectedCard].back = image;
        } catch (err) {
          tempImages.push({});
          if (cardSide === 'front') tempImages[selectedCard].front = image;
          else if (cardSide === 'back') tempImages[selectedCard].back = image;
        }
        return tempImages;
      });

      // Setting the cards data
      setCards((cards) => {
        let tempCards = [...cards];
        if (cardSide === 'front')
          tempCards[selectedCard].frontImageData = image;
        else if (cardSide === 'back')
          tempCards[selectedCard].backImageData = image;
        return tempCards;
      });

      // Showing the success message
      setShowSuccess(true);
    };
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          background: '#F2F4F2',
          marginLeft: '-16px',
          marginRight: '-16px',
          padding: '15px 18px',
          fontFamily: 'SF Compact Display Medium',
          fontSize: '0.8rem',
          lineHeight: '1.2rem',
          color: '#1F4358',
        }}
      >
        <div
          style={{
            color: '#1F4358',
            fontSize: '1.5rem',
            lineHeight: '1.8rem',
            color: '#1F4358',
          }}
        >
          Proof of Vaccination
        </div>
        Please upload images of both the front and back of your vaccination
        card. We have the right to refuse service to you without proper proof of
        vaccination.
        {/* {props.topMessage} */}
      </div>
      <div
        style={{
          height: '1px',
          background: 'rgba(0, 0, 0, 0.1)',
          marginBottom: '15px',
          marginLeft: '-16px',
          paddingLeft: '30px',
          marginRight: '-16px',
          paddingRight: '30px',
          color: 'black',
        }}
      />

      <Alert onClose={handleClose} open={showError} severity="error">
        Please add the images to the previously added cards
      </Alert>

      <Alert onClose={handleClose} open={showSuccess} severity="success">
        Image has attached successfully!
      </Alert>

      {cards.map((card, i) => {
        return (
          <div
            style={{
              fontFamily: 'SF Compact Display Medium',
              fontSize: '16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              color: 'black',
            }}
            key={i}
          >
            Card {i + 1}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
              className="cardBody"
            >
              <>
                {card.frontImageData === '' ? (
                  <label
                    for="files"
                    className="file-upload"
                    style={{
                      marginRight: '5px ',
                      marginBottom: '0px !important',
                    }}
                  >
                    <input
                      type="file"
                      id="files"
                      onChange={fileHandler}
                      accept="image/*"
                    />
                    <a
                      style={{
                        width: '100%',
                        display: 'inline-block',
                        position: 'relative',
                      }}
                      onClick={() => {
                        if (card.frontImageData === '') {
                          setCardSide('front');
                          setSelectedCard(i);
                        }
                      }}
                    >
                      <div className={classes.cameraWrapper}>
                        <CameraIcon />
                        <p
                          style={{
                            color: 'white',
                            marginTop: '9px',
                            height: '20%',
                          }}
                        >
                          {card.frontCard}
                        </p>
                      </div>
                    </a>
                  </label>
                ) : (
                  <a
                    style={{
                      width: '50%',
                      marginRight: '5px',
                      display: 'inline-block',
                      position: 'relative',
                    }}
                  >
                    <i
                      name={i}
                      onClick={(event) => deleteImage(event, i, 'front')}
                      class="close-icon"
                      aria-hidden="true"
                    >
                      <Close style={{ color: 'red' }} />
                    </i>
                    <div className={classes.cameraWrapper}>
                      <img
                        src={card.frontImageData}
                        style={{
                          borderRadius: '0.5rem 0.5rem 0px 0px',
                          width: '100%',
                          height: '80%',
                        }}
                      />
                      <p
                        style={{
                          color: 'white',
                          marginTop: '9px',
                          height: '20%',
                        }}
                      >
                        {card.frontCard}
                      </p>
                    </div>
                  </a>
                )}
              </>

              <>
                {card.backImageData === '' ? (
                  <label
                    for="files"
                    className="file-upload"
                    style={{ marginBottom: '0px !important' }}
                  >
                    <input
                      type="file"
                      id="files"
                      onChange={fileHandler}
                      accept="image/*"
                    />
                    <a
                      style={{
                        width: '100%',
                        display: 'inline-block',
                        position: 'relative',
                      }}
                      onClick={() => {
                        if (card.backImageData === '') {
                          setCardSide('back');
                          setSelectedCard(i);
                        }
                      }}
                    >
                      <div className={classes.cameraWrapper}>
                        <CameraIcon />
                        <p
                          style={{
                            color: 'white',
                            marginTop: '9px',
                            height: '20%',
                          }}
                        >
                          {card.backCard}
                        </p>
                      </div>
                    </a>
                  </label>
                ) : (
                  <a
                    style={{
                      width: '50%',
                      display: 'inline-block',
                      position: 'relative',
                    }}
                  >
                    <i
                      name={i}
                      onClick={(event) => deleteImage(event, i, 'back')}
                      class="close-icon"
                      aria-hidden="true"
                    >
                      <Close style={{ color: 'red' }} />
                    </i>
                    <div className={classes.cameraWrapper}>
                      <img
                        src={card.backImageData}
                        style={{
                          borderRadius: '0.5rem 0.5rem 0px 0px',
                          width: '100%',
                          height: '80%',
                        }}
                      />
                      <p
                        style={{
                          color: 'white',
                          marginTop: '9px',
                          height: '20%',
                        }}
                      >
                        {card.backCard}
                      </p>
                    </div>
                  </a>
                )}
              </>
            </div>
          </div>
        );
      })}

      <div className={classes.divider}></div>

      <a onClick={addCard}>
        <div
          className={classes.cameraWrapper}
          style={{ background: '#6B6E71' }}
        >
          <AddIcon />
          <p style={{ color: 'white', marginTop: '9px' }}>Add a card</p>
        </div>
      </a>

      <Button
        className={classes.btnStyles}
        style={{ background: '#1F4358' }}
        onClick={handleFinish}
        fullWidth
      >
        Finish
      </Button>
      <Button
        style={{
          textTransform: 'none',
          color: '#1F4358',
          fontFamily: 'SF Compact Display Bold',
          fontSize: '20px',
          marginTop: 20,
        }}
        fullWidth
        onClick={props.onCancel}
      >
        I have not been vaccinated
      </Button>
      <Button
        style={{
          textTransform: 'none',
          color: '#1F4358',
          fontFamily: 'SF Compact Display Bold',
          fontSize: '20px',
          marginTop: 20,
        }}
        onClick={props.onCancel}
        fullWidth
      >
        I forgot/misplaced my vaccine card
      </Button>
      <Button
        style={{
          textTransform: 'none',
          color: '#1F4358',
          fontFamily: 'SF Compact Display Bold',
          fontSize: '20px',
          marginTop: 20,
        }}
        onClick={props.onCancel}
        fullWidth
      >
        Cancel
      </Button>

      <Button
        startIcon={<img src={require('../../../../assets/copy.png')} />}
        fullWidth
        style={{
          textTransform: 'none',
          fontFamily: 'SF Compact Display Regular',
          fontSize: '12px',
          marginTop: 30,
        }}
      >
        COVID-19 Screener powered by LineHero
      </Button>
    </div>
  );
};

export default SecondStepForm;
