import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export function Alert(props) {
  return (
    <Snackbar open={props.open} autoHideDuration={6000} onClose={props.onClose}>
      <MuiAlert elevation={6} variant="filled" {...props} />
    </Snackbar>
  );
}
