import React, { useEffect, useRef, useState, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DoneIcon from '../../../../assets/svg/Done.svg';
import moment from 'moment';
import { websiteURL } from '../../../../constants';
import { Link } from 'react-router-dom';
import * as htmlToImage from 'html-to-image';
import LoadingOverlay from 'react-loading-overlay';
var QRCode = require('qrcode.react');

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  iconWrapper: {
    background: '#FAFAFA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    padding: '10px',
  },
  headerWrapper: {
    background: '#F2F4F2',
    padding: '15px 18px',
    fontFamily: 'SF Compact Display Medium',
    fontSize: '14px',
  },
  bodyWrapper: {
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'SF Compact Display Medium',
    fontSize: '14px',
    paddingTop: '20px',
  },
  qrback: {
    background: 'red',
  },
  linkStyle: {
    color: '#007AFF !important',
    fontWeight: '500',
    fontSize: '30px',
    textDecoration: 'underline',
    marginTop: '20px',
  },
});

class ThirdStepForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment().format('L'),
    };
    this.imgRef = React.createRef();
  }

  CaptureScreenShot = async () => {
    let node = this.imgRef.current;
    let submit = this.props.handleSubmit;

    await htmlToImage
      .toJpeg(node, { backgroundColor: 'white' })
      .then(async function (dataUrl) {
        // Generating the ticket and API Calling
        await submit(dataUrl);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  componentDidMount() {
    (async () => {
      this.props.enableSpinner('Loading...');
      await this.CaptureScreenShot();
      this.props.disableSpinner();
    })();
  }

  render() {
    const { classes } = this.props;
    return (
      <LoadingOverlay active={this.state.loader} spinner text="Loading">
        <div ref={this.imgRef}>
          <div
            style={{
              textAlign: 'center',
              paddingTop: '10px',
              paddingBottom: '10px',
              backgroundColor: '#fff',
            }}
          >
            <img
              src={this.props.logo}
              style={{ width: '120px', height: 'auto' }}
            />
          </div>
          <div className={classes.root}>
            <div className={classes.iconWrapper}>
              <DoneIcon />
            </div>
            <div className={classes.headerWrapper}>
              <div
                style={{
                  color: '#1F4358',
                  fontSize: '1.5rem',
                  lineHeight: '1.8rem',
                  fontWeight: '500',
                }}
              >
                {this.props.name}
              </div>
              <p style={{ color: 'black' }}>Phone: {this.props.mobile}</p>
              <p style={{ color: '#065839' }}>
                <b>
                  #{this.props.ticketNumber} &nbsp;- &nbsp;{this.state.date}
                </b>
              </p>
              <p
                style={{
                  color: '#1F4358',
                  fontSize: '0.8rem',
                  lineHeight: '1.2rem',
                }}
              >
                The QR code below opens a link to your digital proof of vaccine
                card. This code has also been sent to your mobile number via SMS
                text message. This information can only be shared by you.
              </p>
            </div>

            <div className={classes.bodyWrapper}>
              <div
                style={{
                  backgroundColor: '#F3FBFA',
                  padding: '15px',
                  borderRadius: '0.6rem',
                  borderStyle: 'solid',
                  borderColor: '#1F4358',
                  borderWidth: '6px',
                }}
              >
                <QRCode
                  value={websiteURL + '/pov/' + this.props.proofID}
                  size={200}
                  renderAs={'svg'}
                  level={'H'}
                  bgColor={'#F3FBFA'}
                  fgColor={'#1F4358'}
                />
              </div>

              <Link to={'/pov/' + this.props.proofID}>
                <div style={{ color: '#1F4358', marginTop: '10px' }}>
                  {websiteURL + 'pov/' + this.props.proofID}
                </div>
              </Link>
              <p>
                <b style={{ color: '#065839' }}>
                  #{this.props.ticketNumber} &nbsp; - &nbsp; {this.state.date}
                </b>
              </p>
            </div>

            <Button
              startIcon={<img src={require('../../../../assets/copy.png')} />}
              fullWidth
              style={{
                backgroundColor: 'white',
                textTransform: 'none',
                fontFamily: 'SF Compact Display Regular',
                fontSize: '12px',
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              COVID-19 Screener powered by LineHero
            </Button>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ThirdStepForm);
