import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
// import LogoBody from './components/Logo';
import FileUpload from './components/FileUpload';
import { withFirebase } from '../../components/Firebase';
import { enableSpinner, disableSpinner } from '../../redux/actions/appActions';
import { name } from 'file-loader';

const Logo = (props) => {
  const [logo, setUrl] = useState('');

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      if (props.uid === '') return;

      try {
        const docRef = props.firebase.db.collection('merchants').doc(props.uid);
        const doc = await docRef.get();
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          if (!mounted) return;

          setUrl(doc.data().merchantLogo);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();

    return () => (mounted = false);
  }, [props.uid]);

  const showResults = async (values) => {
    // window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
    if (!values || !values.files) return;

    const name = values.files[0].name;
    try {
      props.enableSpinner('Uploading...');
      await props.firebase.storageRef
        .child(`${props.uid}/${name}`)
        .put(values.files[0]);
      const url = await props.firebase.storageRef
        .child(`${props.uid}/${name}`)
        .getDownloadURL();
      await props.firebase.db.collection('merchants').doc(props.uid).set(
        {
          merchantLogo: url,
        },
        { merge: true },
      );
    } catch (error) {
      console.log(error);
    } finally {
      props.disableSpinner();
    }
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Company Branding</h3>
          <h5>
            {' '}
            Please upload the most current version of your company's branding
            below. This will be shown at the top of the screener for each
            customer.
          </h5>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="card--not-full-height">
            <CardBody>
              <h3> Logo upload </h3>
              <img
                src={logo}
                style={{ width: '100px', height: 'auto' }}
                alt={name}
              />
              <FileUpload onSubmit={showResults} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default connect(
  (state) => ({
    spinner: state.app.spinner,
    text: state.app.text,
    uid: state.app.uid,
  }),
  (dispatch) => ({
    enableSpinner: (text) => dispatch(enableSpinner(text)),
    disableSpinner: () => dispatch(disableSpinner()),
  }),
)(withFirebase(Logo));
