import React from 'react';
import { Card, CardBody, Col, Row, Container } from 'reactstrap';
import Body from './components/Merchant';

const Merchant = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">My Screener Info </h3>
        <h5>
          The info below is used to create custom messages for your COVID
          screener .{' '}
        </h5>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <Card className="card--not-full-height">
          <CardBody>
            <Body />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Merchant;
