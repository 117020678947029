import React, { Component, Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { compose } from 'recompose';
import LoadingOverlay from 'react-loading-overlay';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { withFirebase } from '../../components/Firebase';
import { setUid } from '../../redux/actions/appActions';
import './style.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.onAuthStateChange();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onAuthStateChange = () => {
    return this.props.firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('user: ', user);
        this.props.setUid(user.uid);
      } else {
        this.props.setUid('');
      }
    });
  };

  render() {
    // const { loaded, loading } = this.state;
    const { spinner, text } = this.props;

    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Fragment>
              <LoadingOverlay active={spinner} spinner text={text}>
                <Router />
              </LoadingOverlay>
            </Fragment>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default hot(module)(
  connect(
    (state) => ({
      spinner: state.app.spinner,
      text: state.app.text,
    }),
    (dispatch) => ({
      setUid: (uid) => dispatch(setUid(uid)),
    }),
  )(compose(withFirebase)(App)),
);
