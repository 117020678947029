import React from 'react';
import LogInForm from './components/LogInForm';
import logo_img from '../../assets/LH-Logo@3x.png';
import ReactGA from 'react-ga';

// Google Analytics Event for Login Page

ReactGA.initialize('UA-206624375-1', {
  titleCase: false,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA.event({
  category: 'Login Page',
  action: 'Login Page Loaded',
  label: Date.now(),
});

const LogIn = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <img src={logo_img} style={{ margin: 10 }} alt="logo" />
        <div className="account__head">
          <h4 className="account__subhead subhead">
            We are a proof of vaccine app that helps customers self-screen for
            COVID-19, before entering your space. We are actively trying to
            reduce the impact of the COVID-19 pandemic.{' '}
          </h4>
        </div>
        <LogInForm />
      </div>
    </div>
  </div>
);

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
