import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import HomeBody from './components/Home';

const Home = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Location</h3>
        <h5> Select the location which you want to manage lines at.</h5>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <HomeBody />
      </Col>
    </Row>
  </Container>
);

export default Home;
