import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import { withFirebase } from '../../../components/Firebase';
// import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  logout = () => {
    localStorage.clear();
    this.props.firebase.doSignOut();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Log Out"
            icon="exit"
            route="/log_in"
            onClick={this.logout}
          />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink
            title="Home"
            route="/merchant"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="My Screener"
            route="/merchant/name"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Company Info"
            route="/merchant/subdomain"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="My Branding"
            route="/merchant/logo"
            onClick={this.hideSidebar}
          />
          {/* <SidebarLink title="Top Message" route="/merchant/top_message" onClick={this.hideSidebar} /> */}
          <SidebarLink
            title="Locations"
            route="/merchant/location"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Customers"
            route="/merchant/line"
            onClick={this.hideSidebar}
          />
          {/* <SidebarLink title="Twilio Message" route="/pages/two" onClick={this.hideSidebar} /> */}
        </ul>
      </div>
    );
  }
}

export default withFirebase(SidebarContent);
