import React, { useEffect, useState } from 'react';

import './style.css';

function ticketComponent(props) {
  const [number, setNumber] = useState('');

  useEffect(() => {
    let number = props.lineNumber.toString();
    const count = 4 - number.length;
    for (let i = 0; i < count; i++) {
      number = '0' + number;
    }
    setNumber(number);
  }, [props.lineNumber]);

  return (
    <div id="ticketDiv">
      <div id="logoDiv" className="vertical-center">
        <img src={props.url} style={{ width: '65px', height: 'auto' }} />
      </div>
      <div id="ticketDivHolder">
        <span id="ticketText">#{number}</span>
      </div>
    </div>
  );
}
export default ticketComponent;
