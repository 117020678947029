import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import LocationBody from './components/Location';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const Location = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(1);
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <h3 className="page-title">Location</h3>
        </Col>

        <div style={{ marginRight: '1rem' }}>
          <AddCircleIcon
            fontSize="large"
            htmlColor="#70bbfd"
            onClick={handleClickOpen}
          >
            Add
          </AddCircleIcon>
        </div>
      </Row>

      <Row>
        <Col>
          <LocationBody open={open} setOpen={setOpen} />
        </Col>
      </Row>
    </Container>
  );
};

export default Location;
