import React from 'react';
import { Link } from 'react-router-dom';
import SignUpForm from './components/SignUpForm';
import logo_img from '../../assets/LH-Logo@3x.png';

const SignUp = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <img src={logo_img} style={{ margin: 10 }} alt="logo" />
        <div className="account__head">
          <h4 className="account__subhead subhead">
            We are a proof of vaccine app that helps customers self-screen for
            COVID-19, before entering your space. We are actively trying to
            reduce the impact of the COVID-19 pandemic.{' '}
          </h4>
        </div>
        <SignUpForm />
        <div className="account__have-acount" style={{ textAlign: 'center' }}>
          <p>
            Already have an account?<Link to="/log_in">Login</Link>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default SignUp;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
