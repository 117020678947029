import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withFirebase } from '../../../components/Firebase';

const Domain = (props) => {
  const [subdomain, setSubdomain] = useState('');
  const [button, setButton] = useState('Edit');
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      if (props.uid === '') return;

      try {
        const docRef = props.firebase.db.collection('merchants').doc(props.uid);
        const doc = await docRef.get();
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          if (!mounted) return;

          setSubdomain(doc.data().companyName);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();

    return () => (mounted = false);
  }, [props.uid]);

  const onChange = (event) => {
    setSubdomain(event.target.value);
  };

  const changeButton = () => {
    if (button === 'Edit') {
      setButton('Update');
      setEditable(true);
    } else {
      updateDomain();
      setButton('Edit');
      setEditable(false);
    }
  };

  const updateDomain = async () => {
    console.log(props.uid);
    await props.firebase.db.collection('merchants').doc(props.uid).set(
      {
        companyName: subdomain,
      },
      { merge: true },
    );
  };

  return (
    <form className="form">
      <h3>URL</h3>
      <Col md={12} style={{ marginTop: '1rem' }}>
        <Row>
          <Col sm={12} md={6}>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <input
                  name="subdomain"
                  type="text"
                  value={subdomain}
                  disabled={!editable}
                  placeholder="Subdomain"
                  onChange={onChange}
                />
              </div>

              <br />
              <h5> {'Your URL : https://www.linehero.com/' + subdomain} </h5>
            </div>
          </Col>
          <Col sm={12} md={3}>
            <div
              className="btn btn-primary account__btn account__btn--small"
              onClick={changeButton}
            >
              {button}
            </div>
          </Col>
        </Row>
      </Col>
    </form>
  );
};

export default reduxForm({
  form: 'domain',
})(
  connect((state) => ({
    uid: state.app.uid,
  }))(withFirebase(Domain)),
);
