import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { withFirebase } from '../../../components/Firebase';
import {
  enableSpinner,
  disableSpinner,
} from '../../../redux/actions/appActions';

const INITIAL_STATE = {
  email: '',
  password: '',
  showPassword: false,
  forgotPassword: false,
  emailSent: false,
  button: 'Send',
};

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validate = (param) => {
    alert(`Please fill ${param} please!`);
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { email, password } = this.state;
    if (email === '') {
      this.validate('email');
      return;
    }
    if (password === '') {
      this.validate('password');
      return;
    }

    try {
      this.props.enableSpinner('Sign in...');
      await this.props.firebase.doSignInWithEmailAndPassword(email, password);
      this.mounted && this.setState(INITIAL_STATE);
      this.props.history.push('/merchant');
    } catch (error) {
      console.log(error);
    } finally {
      this.props.disableSpinner();
    }
  };

  renderEmailSent = () => {
    return (
      <div style={{ width: '100%', color: '#0000ff', marginTop: 5 }}>
        An email has been sent. Please check your mail box.
      </div>
    );
  };

  renderError = (error) => {
    return (
      <div style={{ width: '100%', color: '#ff0000', marginTop: 10 }}>
        {error}
      </div>
    );
  };

  sendPasswordResetEmail = async (event) => {
    event.preventDefault();

    const { email, emailSent } = this.state;

    // console.log(this.props.firebase.auth);
    if (emailSent) {
      window.location.reload();
      return;
    }

    try {
      await this.props.firebase.auth.sendPasswordResetEmail(email);
      this.setState({ emailSent: true, error: null, button: 'Login' });
    } catch (error) {
      console.log(error);
      this.setState({ error: error.message, emailSent: false });
    }
  };

  render() {
    const { showPassword, forgotPassword, error, emailSent, button } =
      this.state;

    if (forgotPassword)
      return (
        <form className="form" onSubmit={this.sendPasswordResetEmail}>
          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <span
                  className="iconify"
                  data-icon="mdi:email"
                  data-inline="false"
                ></span>
              </div>
              <input
                name="email"
                type="email"
                placeholder="Email"
                className="input-without-border-radius"
                onChange={this.onChange}
              />
            </div>
          </div>
          {emailSent && this.renderEmailSent()}
          {error && this.renderError(error)}
          <div style={{ width: '100%' }}>
            <button
              className="btn account__btn account__btn--small"
              style={{ float: 'right' }}
            >
              {button}
            </button>
          </div>
        </form>
      );

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email"
              component="input"
              type="email"
              placeholder="Email"
              onChange={this.onChange}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              onChange={this.onChange}
            />
            <button
              className={`form__form-group-button${
                showPassword ? ' active' : ''
              }`}
              onClick={(e) => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          <div
            className="account__forgot-password"
            style={{ cursor: 'pointer' }}
            onClick={() => this.setState({ forgotPassword: true })}
          >
            Forgot a password?
          </div>
        </div>
        <button className="btn btn-primary account__btn account__btn--small">
          Sign In
        </button>
        <Link
          className="btn btn-outline-primary account__btn account__btn--small"
          to="/sign_up"
        >
          Create Account
        </Link>
      </form>
    );
  }
}

export default reduxForm({
  form: 'log_in_form',
})(
  connect(
    (state) => ({ spinner: state.app.spinner }),
    (dispatch) => ({
      enableSpinner: (text) => dispatch(enableSpinner(text)),
      disableSpinner: () => dispatch(disableSpinner()),
    }),
  )(compose(withFirebase, withRouter)(LogInForm)),
);
