import React, { useState, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import SignUp from '../SignUp/index';
import Home from '../Home';
import Merchant from '../Merchant';
import Subdomain from '../Subdomain';
import Logo from '../Logo';
import TopMessage from '../TopMessage';
import Location from '../Location';
import Line from '../Line';
import Company from '../Company';
import Success from '../../pages/success';
import Fail from '../../pages/fail';
import { withFirebase } from '../../components/Firebase';
import Landing from '../landingpage';
import VaccineProofView from '../VaccinationProof/components/VaccinationProofView/index';

const merchantRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      {/* <Route path="/pages" component={Pages} /> */}
      <Route exact path="/" component={Home} />
      <Route exact path="/merchant" component={Home} />
      <Route exact path="/merchant/name" component={Merchant} />
      <Route exact path="/merchant/subdomain" component={Subdomain} />
      <Route exact path="/merchant/logo" component={Logo} />
      <Route exact path="/merchant/top_message" component={TopMessage} />
      <Route exact path="/merchant/location" component={Location} />
      <Route exact path="/merchant/line" component={Line} />
    </div>
  </div>
);

const userRoutes = (props) => {
  const routes = ['log_in', 'sign_up', 'success', 'fail', 'line', 'pov', ''];
  const pathname = props.location.pathname;
  const route = pathname.split('/')[1];
  const check = routes.includes(route);
  console.log('check: ', route);

  return (
    <div style={{ background: '#f2f4f7' }}>
      {check ? (
        <>
          {/* // If login */}
          <Route exact path="/log_in" component={LogIn} />
          <Route exact path="/sign_up" component={SignUp} />
          <Route path="/success" component={Success} />
          <Route path="/fail" component={Fail} />
          <Route path="/pov" component={VaccineProofView} />
          {/* // If login */}
          {/* <Route exact path="/" component={LogIn} />   */}
          <Route exact path="/" component={Landing} />
        </>
      ) : (
        <Route path="/" component={Company} />
      )}
    </div>
  );
};

const Router = (props) => {
  const [auth, setAuth] = useState(true);
  console.log('---auth----', auth);

  useEffect(() => {
    const unlisten = props.firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        setAuth(true);
      } else {
        setAuth(false);
      }
    });

    return () => unlisten();
  });

  return (
    <MainWrapper>
      <main>
        <Switch>
          {auth ? (
            <Route path="/" component={merchantRoutes} />
          ) : (
            <Route path="/" component={userRoutes} />
          )}
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default withFirebase(Router);
