import React from 'react';

function DoneICon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="25"
      fill="none"
      viewBox="0 0 100 83"
    >
      <path
        stroke="#7ADEB8"
        strokeWidth="21"
        d="M7.511 43.881L38.555 75.663"
      ></path>
      <path
        stroke="#7ADEB8"
        strokeWidth="21"
        d="M23.66 74.796L91.659 7.535"
      ></path>
    </svg>
  );
}

export default DoneICon;
