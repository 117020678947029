import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './containers/App/store';
import App from './containers/App/App';
import Firebase, { FirebaseContext } from './components/Firebase';

render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root'),
);
