export const ENABLE_SPINNER = 'ENABLE_SPINNER';
export const DISABLE_SPINNER = 'DISABLE_SPINNER';
export const SET_UID = 'SET_UID';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';

export function enableSpinner(text) {
  return {
    type: ENABLE_SPINNER,
    payload: text,
  };
}

export function disableSpinner() {
  return {
    type: DISABLE_SPINNER,
  };
}

export function setUid(uid) {
  return {
    type: SET_UID,
    payload: uid,
  };
}

export function setCompanyName(name) {
  return {
    type: SET_COMPANY_NAME,
    payload: name,
  };
}
