import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { withFirebase } from '../components/Firebase';
import {
  enableSpinner,
  disableSpinner,
  setCompanyName,
} from '../redux/actions/appActions';
import Ticket from '../components/ticketComponent/index';

function Success(props) {
  const [name, setName] = useState('');
  const [logo, setLogo] = useState('');
  const [email, setEmail] = useState('');
  const [url, setUrl] = useState('');
  const [companyName, setCompanyName1] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const [lineNumber, setLineNumber] = useState('');
  const [date, setDate] = useState(null);

  useEffect(() => {
    const date = new Date().toString().substring(4, 15);
    setDate(date);

    const init = async () => {
      const url = props.location.state.url;
      const companyName = props.location.state.name;
      const merchantName = props.location.state.name;
      const uid = props.location.state.uid;
      const lineNumber = props.location.state.lineNumber;
      setLineNumber(lineNumber);

      if (!uid) return;

      const doc = await props.firebase.db
        .collection('merchants')
        .doc(uid)
        .get();
      if (!doc.exists) return;
      setName(doc.data().companyName);
      setLogo(doc.data().merchantLogo);
      setEmail(doc.data().merchantEmail);
      setUrl(url);
      setCompanyName1(companyName);
      setMerchantName(merchantName);
    };

    init();
  }, []);

  return (
    <Container style={{ maxWidth: '720px', paddingLeft: 0, paddingRight: 0 }}>
      <div
        style={{
          textAlign: 'center',
          paddingTop: '20px',
          paddingBottom: '20px',
          backgroundColor: '#fff',
          borderBottomWidth: '1px',
          borderBottomColor: 'rgba(0, 0, 0, 0.1)',
          borderBottomStyle: 'solid',
        }}
      >
        <img src={logo} style={{ width: '120px', height: 'auto' }} />
      </div>
      {/* 
      let's say we change some codes like this. After you change the codes and save it.
      after that, open terminal
      run yarn build as I described in the document
      after that, run the following commands as I described in the document. 

      That's all
      
      */}
      <Card>
        <CardBody>
          {/* {props.topMessage} */}
          <div
            style={{
              background: '#F2F4F2',
              marginLeft: '-30px',
              paddingLeft: '30px',
              marginRight: '-30px',
              paddingRight: '30px',
              paddingBottom: '20px',
              marginTop: '-30px',
              paddingTop: '20px',
              fontFamily: 'SF Compact Display Regular',
              fontSize: '15px',
            }}
          >
            This is your one-day certificate of entry to {merchantName}{' '}
            facilities
          </div>
          <div
            style={{
              height: '1px',
              background: 'rgba(0, 0, 0, 0.1)',
              marginBottom: '30px',
              marginLeft: '-30px',
              paddingLeft: '30px',
              marginRight: '-30px',
              paddingRight: '30px',
            }}
          />
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <span
              style={{
                fontFamily: 'SF Compact Display Black',
                fontSize: '36px',
                textAlign: 'center',
              }}
            >
              {date}
            </span>
            <Ticket url={logo} lineNumber={lineNumber} />
          </div>
          <div
            style={{
              fontFamily: 'SF Compact Display Regular',
              fontSize: '14px',
            }}
          >
            Attention: <br />
            <br />
            The person with this certificate has attested to the daily building
            requirements and is permitted to enter {merchantName} facilities
            today. <br />
            <br />
            Please be ready to show this certificate today if requested.
            <br />
            Important: This certificate is only valid for the calendar date
            indicated. If you have questions or concerns about facility access
            with this certificate, please contact {merchantName} at {email}
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

export default connect(
  (state) => ({
    spinner: state.app.spinner,
    companyName: state.app.companyName,
  }),
  (dispatch) => ({
    enableSpinner: (text) => dispatch(enableSpinner(text)),
    disableSpinner: () => dispatch(disableSpinner()),
    setCompanyName: (name) => dispatch(setCompanyName(name)),
  }),
)(withFirebase(Success));
