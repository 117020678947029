import React, { useState, useEffect } from 'react';
import { CardBody, Col, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withFirebase } from '../../../components/Firebase';
import {
  enableSpinner,
  disableSpinner,
} from '../../../redux/actions/appActions';
import Paper from '@material-ui/core/Paper';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = {
  root: {
    width: '100%',
    marginTop: 24,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
};

const classes = useStyles2;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const Location = (props) => {
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [locations, setLocations] = useState([]);
  const [button, setButton] = useState('Edit');
  const [editable, setEditable] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [isChange, setIsChange] = useState(false);
  const [index, setIndex] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      if (props.uid === '') return;

      getData(mounted);
    }
    fetchData();

    return () => (mounted = false);
  }, [props.uid]);

  useEffect(() => {
    console.log('AAA_OPEN', props.open);
    if (props.open) {
      handleClickOpen();
    }
  }, [props.open]);

  const getData = async (mounted) => {
    try {
      props.enableSpinner('');
      const docRef = props.firebase.db.collection('merchants').doc(props.uid);
      const doc = await docRef.get();
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        const locations = doc.data().locations;
        if (locations.length === 0) return;
        if (!mounted) return;
        mounted && setLocations(locations);
      }
    } catch (error) {
      console.log(error);
    } finally {
      props.disableSpinner();
    }
  };

  const updateLocation = async () => {
    await props.firebase.db
      .collection('merchants')
      .doc(props.uid)
      .set(
        {
          locations: [
            {
              address: address,
              state: state,
              city: city,
              zip: zip,
            },
          ],
        },
        { merge: true },
      );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };

  const deleteLocation = async (location) => {
    const newLocations = locations.filter((item) => item !== location);

    try {
      await props.firebase.db.collection('merchants').doc(props.uid).update({
        locations: newLocations,
      });
      getData(true);
    } catch (error) {
      console.log(error);
    }
  };

  const addNewLocation = async () => {
    const newLocation = { address, state, city, zip };
    const newLocations = locations.concat(newLocation);

    try {
      await props.firebase.db.collection('merchants').doc(props.uid).update({
        locations: newLocations,
      });
      getData(true);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const changeLocation = (index) => {
    setAddress(locations[index].address);
    setState(locations[index].state);
    setCity(locations[index].city);
    setZip(locations[index].zip);
    setIndex(index);
    setIsChange(true);
    handleClickOpen();
  };

  const editLocation = async () => {
    const newLocations = locations;
    newLocations[index].address = address;
    newLocations[index].city = city;
    newLocations[index].zip = zip;
    newLocations[index].state = state;

    try {
      await props.firebase.db.collection('merchants').doc(props.uid).update({
        locations: newLocations,
      });
      getData(true);
      handleClose();

      setIsChange(false);
      setIndex(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <div>
      <Paper className="container-table">
        {width > 900 ? (
          <Table style={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Zip</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.address}</TableCell>
                    <TableCell>{item.city}</TableCell>
                    <TableCell>{item.state}</TableCell>
                    <TableCell>{item.zip}</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => changeLocation(index)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => deleteLocation(item)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={6}
                  count={locations.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        ) : (
          <div>
            {locations.map((item, index) => (
              <div>
                <div>Address: {item.address}</div>
                <div>City: {item.city}</div>
                <div>State: {item.state}</div>
                <div>Zip: {item.zip}</div>
                <div style={{ marginTop: '8px' }}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => changeLocation(index)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => deleteLocation(item)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Add a location</DialogTitle>
          <DialogContent>
            <div className="form">
              <div className="form__form-group">
                <span className="form__form-group-label">Address</span>
                <div className="form__form-group-field">
                  <input
                    name="address"
                    type="text"
                    value={address}
                    disabled={!editable}
                    placeholder="Address"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <Row>
                <Col md={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">City</span>
                    <div className="form__form-group-field">
                      <input
                        name="city"
                        type="text"
                        value={city}
                        disabled={!editable}
                        placeholder="City"
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">State</span>
                    <div className="form__form-group-field">
                      <input
                        name="state"
                        type="text"
                        value={state}
                        disabled={!editable}
                        placeholder="State"
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Zip</span>
                    <div className="form__form-group-field">
                      <input
                        name="zip"
                        type="text"
                        value={zip}
                        disabled={!editable}
                        placeholder="Zip"
                        onChange={(e) => setZip(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={isChange ? editLocation : addNewLocation}
              color="primary"
              autoFocus
            >
              {isChange ? 'Change' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  );
};

export default connect(
  (state) => ({
    uid: state.app.uid,
  }),
  (dispatch) => ({
    enableSpinner: (text) => dispatch(enableSpinner(text)),
    disableSpinner: () => dispatch(disableSpinner()),
  }),
)(withFirebase(Location));
