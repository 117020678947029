import React from 'react';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-206624375-1', {
  titleCase: false,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});
ReactGA.pageview(window.location.pathname + window.location.search);

function gotoSection(section) {
  ReactGA.event({
    category: 'MenuButtonClicked',
    action: section + 'mobile_selected',
    label: 'MenuModal',
  });

  let sectionName = section;

  switch (sectionName) {
    case 'login':
      window.location.replace('/log_in');
      console.log('LOGIN');
      break;

    case 'faq':
      window.location.replace('/#faq2021');
      break;

    case 'howitworks':
      window.location.replace('/#howitworks2021');
      break;

    case 'signup':
      window.location.replace('/sign_up');
      break;
    case 'try':
      window.location.replace('/cafe');
      break;
    default:
      console.log('nothing pressed.');
  }
}

const LandingPageMenu = () => (
  <>
    <div className="mobile_cta" id="mobile_menu">
      <button
        className="button w-button"
        onClick={() => gotoSection('howitworks')}
      >
        HOW IT WORKS
      </button>

      <button
        className="button-green w-button"
        onClick={() => gotoSection('try')}
      >
        TRY IT OUT
      </button>

      <button
        className="button-green w-button"
        onClick={() => gotoSection('signup')}
      >
        SIGN UP
      </button>

      <button className="button w-button" onClick={() => gotoSection('faq')}>
        FAQ
      </button>

      <button className="button w-button" onClick={() => gotoSection('login')}>
        LOGIN
      </button>
    </div>
  </>
);

export default LandingPageMenu;
