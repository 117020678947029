import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withFirebase } from '../../../components/Firebase';
import Checkbox from '@material-ui/core/Checkbox';
import {
  enableSpinner,
  disableSpinner,
} from '../../../redux/actions/appActions';

const Merchant = (props) => {
  const [merchantName, setMerchantName] = useState('');
  const [topMessage, setTopMessage] = useState('');
  const [button, setButton] = useState('Edit');
  const [editable, setEditable] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [discountUrl, setDiscountUrl] = useState('');
  const [sorryMessage, setSorryMessage] = useState('');
  const [enableVaccinationProof, setEnableVaccinationProof] = useState(false);

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      if (props.uid === '') return;

      try {
        const docRef = props.firebase.db.collection('merchants').doc(props.uid);
        const doc = await docRef.get();
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          if (!mounted) return;

          setMerchantName(doc.data().merchantName);
          setTopMessage(doc.data().topMessage);
          setTextMessage(doc.data().merchantTextMessage);
          setEnableVaccinationProof(doc.data().enableVaccinationProof);
          if (doc.data().sorryMessage) setSorryMessage(doc.data().sorryMessage);
          if (doc.data().discountUrl) setDiscountUrl(doc.data().discountUrl);
        }
        props.disableSpinner();
      } catch (error) {
        console.log(error);
        props.disableSpinner();
      }
    }
    props.enableSpinner('Loading');
    fetchData();

    return () => (mounted = false);
  }, [props.uid]);

  const changeButton = () => {
    if (button === 'Edit') {
      setButton('Update');
      setEditable(true);
    } else {
      update();
      setButton('Edit');
      setEditable(false);
    }
  };

  const handleVaccinationProof = async (event) => {
    props.enableSpinner('Updating');
    setEnableVaccinationProof(event.target.checked);
    try {
      await props.firebase.db.collection('merchants').doc(props.uid).set(
        {
          enableVaccinationProof: event.target.checked,
        },
        { merge: true },
      );
      props.disableSpinner();
    } catch (err) {
      console.log(err);
      props.disableSpinner();
    }
  };

  const update = async () => {
    props.enableSpinner('Updating');
    await props.firebase.db.collection('merchants').doc(props.uid).set(
      {
        merchantName: merchantName,
        topMessage: topMessage,
        merchantTextMessage: textMessage,
        discountUrl: discountUrl,
        sorryMessage: sorryMessage,
        enableVaccinationProof,
      },
      { merge: true },
    );
    props.disableSpinner();
  };

  return (
    <form className="form">
      <Col md={12}>
        <Row>
          <Col sm={12} md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Enable Vaccination Proof
              </span>
              <Checkbox
                checked={enableVaccinationProof}
                onChange={handleVaccinationProof}
                name="checkedB"
                color="primary"
              />
            </div>
          </Col>
          <Col sm={12} md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Merchant Name</span>
              <div className="form__form-group-field">
                <input
                  name="merchantName"
                  type="text"
                  value={merchantName}
                  disabled={!editable}
                  placeholder="Merchant Name"
                  onChange={(event) => setMerchantName(event.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Greeting Message (Displayed at the top of the COVID Screener)
              </span>
              <div className="form__form-group-field">
                <textarea
                  name="topMessage"
                  type="text"
                  value={topMessage}
                  disabled={!editable}
                  placeholder="Top Message"
                  onChange={(event) => setTopMessage(event.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Post COVID Screener Message (Displayed after customer has
                successfully completed the survey)
              </span>
              <div className="form__form-group-field">
                <textarea
                  name="textMessage"
                  type="text"
                  value={textMessage}
                  disabled={!editable}
                  placeholder="App Text Message"
                  onChange={(event) => setTextMessage(event.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Screening failure message (Displayed when customer fails Covid
                screening)
              </span>
              <div className="form__form-group-field">
                <textarea
                  name="textMessage"
                  type="text"
                  value={sorryMessage}
                  disabled={!editable}
                  placeholder="Screening failure message....."
                  onChange={(event) => setSorryMessage(event.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Discount message. ( Displayed as an incentive or coupon when
                customer fails COVID screening){' '}
              </span>
              <div className="form__form-group-field">
                <textarea
                  name="discountUrl"
                  type="text"
                  value={discountUrl}
                  disabled={!editable}
                  placeholder="Discount message.... (Optional)"
                  onChange={(event) => setDiscountUrl(event.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={3}>
            <div
              className="btn btn-primary account__btn account__btn--small"
              onClick={changeButton}
            >
              {button}
            </div>
          </Col>
        </Row>
      </Col>
    </form>
  );
};

export default reduxForm({
  form: 'merchant',
})(
  connect(
    (state) => ({
      uid: state.app.uid,
      spinner: state.app.spinner,
    }),
    (dispatch) => ({
      enableSpinner: (text) => dispatch(enableSpinner(text)),
      disableSpinner: () => dispatch(disableSpinner()),
    }),
  )(withFirebase(Merchant)),
);
