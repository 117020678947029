import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const FirstStepForm = (props) => {
  const handleSubmit = () => {
    if (props.name === '') {
      validate('name');
      return;
    }
    if (props.mobile === '') {
      validate('mobile number');
      return;
    }
    if (props.location === '') {
      validate('location');
      return;
    }

    props.changeStep(1);
  };

  const handleChange = (event) => {
    props.setLocation(event.target.value);
  };

  // Form Validator
  const validate = (param) => {
    alert(`${param} is empty!`);
  };

  return (
    <>
      <div
        style={{
          background: '#F2F4F2',
          marginLeft: '-16px',
          marginRight: '-16px',
          padding: '15px 18px',
          fontFamily: 'SF Compact Display Medium',
          fontSize: '0.8rem',
          lineHeight: '1.2rem',
          color: '#1F4358',
        }}
      >
        {props.topMessage}
      </div>
      <div
        style={{
          height: '1px',
          background: 'rgba(0, 0, 0, 0.1)',
          marginBottom: '15px',
          marginLeft: '-16px',
          paddingLeft: '30px',
          marginRight: '-16px',
          paddingRight: '30px',
        }}
      />
      <div
        style={{
          fontFamily: 'SF Compact Display Medium',
          fontSize: '16px',
        }}
      >
        All form fields are required
        {/* <div id='ticketDiv'>
                #0023
            </div> */}
        <form style={{ marginTop: 10 }}>
          <InputBase
            name="name"
            type="text"
            style={{
              border: '1px solid #ced4da',
              borderRadius: 4,
              padding: '6px 12px',
              backgroundColor: '#fff',
            }}
            required={true}
            fullWidth
            placeholder="Name or Initials"
            onChange={(event) => props.setName(event.target.value)}
          />
          <InputBase
            name="mobile"
            type="phone"
            style={{
              border: '1px solid #ced4da',
              borderRadius: 4,
              padding: '6px 12px',
              backgroundColor: '#fff',
              marginTop: 10,
            }}
            required={true}
            fullWidth
            placeholder="Mobile Number"
            onChange={(event) => props.setMobile(event.target.value)}
          />
          <FormControl
            variant="outlined"
            style={{ width: '100%', marginTop: 10 }}
          >
            <InputLabel>Location</InputLabel>
            <Select
              label="Location"
              value={props.location}
              onChange={handleChange}
            >
              {props.locations.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.address}
                  onClick={() => props.setLocation(item.address)}
                >
                  {item.address}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </div>
      <div
        style={{
          height: '1px',
          background: 'rgba(0, 0, 0, 0.1)',
          marginBottom: '15px',
          marginTop: '25px',
          marginLeft: '-16px',
          paddingLeft: '30px',
          marginRight: '-16px',
          paddingRight: '30px',
        }}
      />
      <div
        id="test"
        style={{
          fontFamily: 'SF Compact Display Medium',
          fontSize: '16px',
          marginBottom: 15,
        }}
      >
        Please attest to the following statements.
      </div>
      <div
        style={{
          fontFamily: 'SF Compact Display Regular',
          fontSize: '14px',
          marginBottom: 30,
          color: 'black',
        }}
      >
        1. I agree to wear a face covering per current {props.merchantName}{' '}
        facility guidelines. <br />
        <br />
        2. I have not experienced any of the following symptoms in the last 72
        hours (symptoms related to pre-existing diagnosed health conditions do
        not apply).
        <br />
        &nbsp;&nbsp;&nbsp;&#42;&nbsp;Fever > 100.4F/38C
        <br />
        &nbsp;&nbsp;&nbsp;&#42;&nbsp;Cough
        <br />
        &nbsp;&nbsp;&nbsp;&#42;&nbsp;Shortness of breath
        <br />
        &nbsp;&nbsp;&nbsp;&#42;&nbsp;Malaise/fatigue
        <br />
        &nbsp;&nbsp;&nbsp;&#42;&nbsp;Muscle pain
        <br />
        &nbsp;&nbsp;&nbsp;&#42;&nbsp;Headache
        <br />
        &nbsp;&nbsp;&nbsp;&#42;&nbsp;Diarrhea
        <br />
        &nbsp;&nbsp;&nbsp;&#42;&nbsp;Sore Throat
        <br />
        &nbsp;&nbsp;&nbsp;&#42;&nbsp;Loss of sense of smell or tate
        <br />
        &nbsp;&nbsp;&nbsp;&#42;&nbsp;Chills
        <br />
        &nbsp;&nbsp;&nbsp;&#42;&nbsp;Repeated shaking with chills
        <br />
        <br />
        3. I agree to follow social distancing while at {props.merchantName}, I
        agree to follow safety guidelines provided.
        <br />
        <br />
        4. I have not been exposed to a known COVID-19 diagnosed individual
        without wearing appropriate Personal Protective Equipment (per current{' '}
        {props.merchantName} guidelines.)
        <br />
        <br />
        5. I am not currently awaiting results for a COVID-19 test
      </div>

      <Button
        style={{
          background: '#1F4358',
          textTransform: 'none',
          color: '#fff',
          fontFamily: 'SF Compact Display Bold',
          fontSize: '20px',
        }}
        onClick={handleSubmit}
        fullWidth
      >
        Next
      </Button>
      <Button
        style={{
          textTransform: 'none',
          color: '#1F4358',
          fontFamily: 'SF Compact Display Bold',
          fontSize: '20px',
          marginTop: 20,
        }}
        onClick={props.onCancel}
        fullWidth
      >
        Cancel
      </Button>

      <Button
        startIcon={<img src={require('../../../../assets/copy.png')} />}
        fullWidth
        style={{
          textTransform: 'none',
          fontFamily: 'SF Compact Display Regular',
          fontSize: '12px',
          marginTop: 30,
        }}
      >
        COVID-19 Screener powered by LineHero
      </Button>
    </>
  );
};

export default FirstStepForm;
