import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import Button from '@material-ui/core/Button';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { withFirebase } from '../components/Firebase';
import {
  enableSpinner,
  disableSpinner,
  setCompanyName,
} from '../redux/actions/appActions';

function Fail(props) {
  const [name, setName] = useState('');
  const [logo, setLogo] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName1] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const [discountUrl, setDiscountUrl] = useState('');
  const [sorryMessage, setSorryMessage] = useState('');

  useEffect(() => {
    const init = async () => {
      const companyName = props.location.state.name;
      const merchantName = props.location.state.name;
      const uid = props.location.state.uid;
      console.log('uid: ', uid);
      if (!uid) return;

      const doc = await props.firebase.db
        .collection('merchants')
        .doc(uid)
        .get();
      if (!doc.exists) return;
      setName(doc.data().companyName);
      setLogo(doc.data().merchantLogo);
      setEmail(doc.data().merchantEmail);
      setCompanyName1(companyName);
      setMerchantName(merchantName);
      if (doc.data().sorryMessage) {
        const message = doc
          .data()
          .sorryMessage.replace('<MERCHANTNAME>', merchantName);
        setSorryMessage(message);
      }
      if (doc.data().discountUrl) setDiscountUrl(doc.data().discountUrl);
    };

    init();
  });

  const redirect = () => {
    window.location.href = discountUrl;
  };

  return (
    <Container style={{ maxWidth: '720px', paddingLeft: 0, paddingRight: 0 }}>
      <div
        style={{
          textAlign: 'center',
          paddingTop: '20px',
          paddingBottom: '20px',
          backgroundColor: '#fff',
          borderBottomWidth: '1px',
          borderBottomColor: 'rgba(0, 0, 0, 0.1)',
          borderBottomStyle: 'solid',
        }}
      >
        <img src={logo} style={{ width: '120px', height: 'auto' }} />
      </div>
      <Card>
        <CardBody>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <img src={logo} style={{ width: '240px', height: 'auto' }} />
          </div>
          <div
            style={{
              fontFamily: 'SF Compact Display Medium',
              fontSize: '36px',
              textAlign: 'center',
              marginBottom: '20px',
              marginTop: '10px',
            }}
          >
            We're Sorry.
          </div>
          <div
            style={{
              fontFamily: 'SF Compact Display Regular',
              fontSize: '12px',
            }}
          >
            {sorryMessage === '' ? (
              <>
                While we can't allow access, we're here to help.
                <br />
                <br />
                Thank you for reporting your response. Your response is
                confidential and does not impact your relationship with{' '}
                {merchantName} in any way.
                <br />
                <br />
                You are not allowed to enter {merchantName} facilities today
                because you could not agree to the required daily self
                attestation. You may enter after:
                <br />
                <br />
                1. A Minimum of 10 days since symptoms first appeared and you
                have been symptom free for 72 hours, OR
                <br />
                <br />
                2. You've completed your 14 day quarantine period if you were in
                close contact with an individual diagnosed with COVID-19, AND{' '}
                <br />
                <br />
                3. You agree to follow social distancing quidelines while
                present.
              </>
            ) : (
              sorryMessage
            )}
          </div>
          {discountUrl !== '' && (
            <>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '40px',
                  marginBottom: '40px',
                  fontFamily: 'SF Compact Display Medium',
                  fontSize: '12px',
                }}
              >
                When you come back, enjoy %10 off your next purchase.
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontFamily: 'SF Compact Display Medium',
                  fontSize: '20px',
                  border: 'solid 2px #4cba4c',
                  borderRadius: '5px',
                  color: '#4cba4c',
                  padding: '10px 15px',
                  cursor: 'pointer',
                }}
                onClick={redirect}
              >
                Send me a coupon for %10 off
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
}

export default connect(
  (state) => ({
    spinner: state.app.spinner,
    companyName: state.app.companyName,
  }),
  (dispatch) => ({
    enableSpinner: (text) => dispatch(enableSpinner(text)),
    disableSpinner: () => dispatch(disableSpinner()),
    setCompanyName: (name) => dispatch(setCompanyName(name)),
  }),
)(withFirebase(Fail));
