import { Landscape } from '@material-ui/icons';
import React from 'react';
import LandingPageMenu from './landingpagemenu';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-206624375-1', {
  titleCase: false,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});
ReactGA.pageview(window.location.pathname + window.location.search);

function openMenu() {
  let menudiv = document.getElementById('mobile_menu');
  menudiv.style.display = menudiv.style.display == 'block' ? 'none' : 'block';
  let menustatus =
    menudiv.style.display == 'block' ? 'MENU_OPEN' : 'MENU_CLOSE';
  ReactGA.event({
    category: 'MenuActivity',
    action: menustatus,
    label: 'MenuModal',
  });
}

function gotoSection(section) {
  ReactGA.event({
    category: 'MenuButtonClicked',
    action: section + 'DESKTOP_selected',
    label: 'MenuModal',
  });

  let sectionName = section;

  switch (sectionName) {
    case 'login':
      window.location.replace('/log_in');
      console.log('LOGIN');
      break;

    case 'faq':
      window.location.replace('/#faq2021');
      break;

    case 'howitworks':
      window.location.replace('/#howitworks2021');
      break;

    case 'signup':
      window.location.replace('/sign_up');
      //window.location.replace("https://forms.gle/WYw7gfM2iCGQYXJr5");
      break;

    case 'try':
      window.location.replace('/cafe');
      //window.location.replace("https://forms.gle/WYw7gfM2iCGQYXJr5");
      break;

    default:
      console.log('nothing pressed.');
  }
}

const Landing = () => (
  <>


    
<div className="ethback wf-section">

<div data-collapse="medium" data-animation="default" data-duration="400" role="banner" className="navbar w-nav">
      <div className="container m_menu w-container">

        <nav role="navigation" className="nav-menu w-nav-menu">
          <button onClick={()=>gotoSection('howitworks')} className="w-nav-link">How It Works</button>
          <button onClick={()=>gotoSection('faq')} className="w-nav-link">FAQ</button>
          <button onClick={()=>gotoSection('signup')} className="w-nav-link">Sign Up</button>
          <button onClick={()=>gotoSection('try')} className="w-nav-link">Try It Out</button>
          <button onClick={()=>gotoSection('login')} className="button-2 w-button">Login</button>
          {/* <a href="/log_in" className="button-2 w-button">Login</a> */}
        </nav>
        <div className="w-nav-button">
          <div className="icon w-icon-nav-menu" onClick={openMenu}></div>
        </div>
      </div>

      <LandingPageMenu></LandingPageMenu>

      <div className="div-block-2 m_header1">
        <img
          src="images/LH-Logo3x.png"
          loading="lazy"
          sizes="(max-width: 479px) 92vw, 300px"
          srcSet="images/LH-Logo3x-p-500.png 500w, images/LH-Logo3x.png 504w"
          alt=""
          className="image-4"
        />
        <div className="columns-2 w-row">
          <div className="column w-col w-col-6">
            <img
              src="images/OpenImge3x.png"
              loading="lazy"
              width="320"
              sizes="(max-width: 479px) 83vw, (max-width: 767px) 381px, (max-width: 991px) 47vw, 381px"
              srcSet="images/OpenImge3x-p-500.png 500w, images/OpenImge3x-p-800.png 800w, images/OpenImge3x.png 864w"
              alt=""
              className="image-5"
            />
          </div>
          <div className="w-col w-col-6">
            <div className="div-block-6">
              <div className="text-block-2 m_header">
                Protect your customers and reduce the spread, while keeping in
                touch. 
              </div>
              {/* <div className="text-block-3 m_message">We help your customers self-screen for COVID-19 before entering your space.</div> */}
              <div className="text-block-3 m_message">
                We are a proof of vaccine app that helps customers self-screen
                for COVID-19, before entering your space.
              </div>
              {/* <a href="mailto:signup@linehero.com?subject=We%20Need%20LineHero!" className="button w-button">
                SIGN UP MY BUSINESS
            </a> */}
              <button
                className="button-green w-button"
                onClick={() => gotoSection('signup')}
              >
                SIGN UP
              </button>

              <button
                className="button-green w-button"
                onClick={() => gotoSection('try')}
              >
                TRY IT OUT
              </button>
            </div>
          </div>
        </div>
        <div className="div-block-4">
          <div className="div-block-3"></div>
        </div>
      </div>
    </div>

    <div id="howitworks2021" className="section-2 wf-section">
      <div className="div-block-5">
        <h1 id="howitworks" className="heading">
          How it works.
        </h1>
        <div className="d_usage w-container">
          <div>
            <div>
              <div className="paragraph-2">
                <div className="bold-text">
                  <br />
                  <br />
                  Step 1
                </div>
                <br />
                Fill out a few small details about yourself into our proof of
                vaccine app.
                <br />
                <br />
              </div>
            </div>
            <div className="div-block-7">
              <img
                src="images/personalfields3x.png"
                loading="lazy"
                width="317"
                sizes="(max-width: 479px) 83vw, (max-width: 1279px) 317px, 100vw"
                srcSet="images/personalfields3x.png 500w, images/personalfields3x.png 800w, images/personalfields3x.png 1080w, images/personalfields3x.png 1600w, images/personalfields3x.png 2000w, images/personalfields3x.png 2556w"
                alt=""
              />
            </div>
          </div>
          <div>
            <div>
              <div className="paragraph-3">
                <div className="bold-text">
                  <br />
                  <br />
                  Step 2
                </div>
                <br />
                Confirm that you are symptom free, and that you have been
                vaccinated.
                <br />
              </div>
            </div>
            <div className="div-block-7">
              <img
                src="images/symptoms3x.png"
                loading="lazy"
                width="317"
                sizes="(max-width: 479px) 83vw, (max-width: 1279px) 317px, 100vw"
                srcSet="images/symptoms3x.png 500w, images/symptoms3x.png 800w, images/symptoms3x.png 1080w, images/symptoms3x.png 1600w, images/symptoms3x.png 2000w, images/symptoms3x.png 2556w"
                alt=""
              />
            </div>
          </div>
          <div>
            <div>
              <div className="paragraph-4">
                <div className="bold-text">
                  <br />
                  <br />
                  Step 3
                </div>
                <br />
                Receive a text message with your proof of vaccine as well as a
                number of your spot in line!
                <br />
              </div>
            </div>
            <div className="div-block-7">
              <img
                src="images/ticket.png"
                loading="lazy"
                width="317"
                sizes="(max-width: 479px) 83vw, (max-width: 1279px) 317px, 100vw"
                srcSet="images/ticket.png 500w, images/ticket.png 710w"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="w-layout-grid grid-2 m_usagegrid">
          <div
            id="w-node-_67ae09eb-f878-59fc-bad7-b06bff7bd384-bf7203b6"
            className="paragraph-2"
          >
            <div className="bold-text">
              <br />
              <br />
              Step 1
            </div>
            <br />
            Fill out a few small details about yourself.
            <br />
            <br />
          </div>
          <div className="paragraph-3">
            <div className="bold-text">
              <br />
              <br />
              Step 2
            </div>
            <br />
            Confirm that you are symptom free.
            <br />
          </div>
          <div className="paragraph-4">
            <div className="bold-text">
              <br />
              <br />
              Step 3
            </div>
            <br />
            Receive a text message with your spot in our Virtual Line!
            <br />
          </div>
          <img
            src="images/personalfields3x.png"
            loading="lazy"
            width="188"
            sizes="(max-width: 479px) 100vw, 188px"
            srcSet="images/personalfields3x.png 500w, images/personalfields3x.png 800w, images/personalfields3x.png 1080w, images/personalfields3x.png 1600w, images/personalfields3x.png 2000w, images/personalfields3x.png 2556w"
            alt=""
          />
          <img
            src="images/symptoms3x.png"
            loading="lazy"
            width="188"
            sizes="(max-width: 479px) 100vw, 188px"
            srcSet="images/symptoms3x.png 500w, images/symptoms3x.png 800w, images/symptoms3x.png 1080w, images/symptoms3x.png 1600w, images/symptoms3x.png 2000w, images/symptoms3x.png 2556w"
            alt=""
          />
          <img
            src="images/ticket3x.png"
            loading="lazy"
            width="188"
            id="w-node-_506a1838-d8fe-dd00-2d2b-eb28c27c8ebb-bf7203b6"
            srcSet="images/ticket3x.png 500w, images/ticket3x.png 800w, images/ticket3x.png 1080w, images/ticket3x.png 1600w, images/ticket3x.png 2000w, images/ticket3x.png 2130w"
            sizes="(max-width: 479px) 100vw, 188px"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>

  <div className="div-block-3"></div>
  <div id="faq2021" className="wf-section">
    <h1 className="heading">FAQ</h1>
    <details>
    <summary> What is LineHero?</summary>
    LineHero is a mobile web app, that helps customers self-screen for COVID-19, and show proof of vaccination before entering brick and mortar businesses, and live events. We cater to restaurants, retail establishments, concerts , gyms and cafe's.
   </details>
    <details>
    <summary> How much does LineHero cost?</summary>
    For now , LineHero can be used at no charge, for businesses and event centers that are opening back up. We are a small business and we realize that doing good, is our focus right now. We are also using this time to learn more about our target customers, and build relationships with brands that are reaching into the future.
   </details>
   <details>
   
    <summary> Is LineHero a vaccine passport?</summary>
  LineHero is an app that allows customers, eventgoers, and many others self screen for COVID-19. Our goal is to create premium digital tools for cafe owners, event organizers, gyms, restaurants and any small business that relies on foot traffic. We want people to be together, but safely.
   </details>
   <details>
<summary>What kind of tests will be accepted?</summary>
A negative COVID-19 test result (molecular, PCR or antigen) that was administered by an official testing center. Test must be taken no more than 72 hours prior to entry. Home testing kit results will not be accepted for entry.
</details>
<details>
<summary>I have tickets to a show that’s coming up quickly… what if I don’t have time to get a test?</summary>
If you have purchased a ticket to an event that is occurring , you are required to provide proof of full vaccination or a negative Covid test taken within 72 hours of the event. If you are unable or unwilling to provide the requested documentation, or if you do not have the ability to provide a test result taken within the 72-hour window, you may reach out to the organizers of the event , or the owners of the event or establishment
</details>
<details>
<summary>How do I show proof of vaccination when I arrive?</summary>
Simple . You can use our app , or acceptable documentation may be a physical copy of a COVID-19 Vaccination Record Card, a digital copy of such card or such other proof as is permitted locally.
</details>
<details>
<summary>I’m visiting from another country, where other covid vaccines are approved for use (astrazeneca, for example), will I be able to get in?</summary>
Yes, as long as you’re considered fully vaccinated. Each event may have additional instructions.
</details>
<details>
<summary>What if I forget my proof of vaccination?</summary>
Each event may have additional instructions.You won’t get in. So please don’t.
</details>
<details>
<summary>What if I have a medical condition or religious belief that exempts me from getting vaccinated?</summary>
Any patron needing an exemption due to a medical condition, disability or religious belief will need to complete the attached form and bring the signed form along with a proof of a negative COVID test (molecular, PCR or antigen) that was administered by an official testing center to the event. Test must be taken no more than 72 hours prior to entry. Home testing kit results will not be accepted for entry.
</details>
<details>
<summary>What about attendees under the age of 12?</summary>
As the vaccine has not been approved for use on kids under age 12, a negative Covid test taken within 72 hours of the event will be required.
</details>
<details>
<summary>Will I still have to wear a mask?</summary>
Where not required by law, we are STRONGLY ENCOURAGING you to continue wearing a mask at our events. It helps stops the spread of disease.
</details>
<details>
<summary>I have no intention of getting vaccinated, so what now?</summary>
We wish you would reconsider your choice because this is a matter of public health, and we won’t get to have nice things like normal concerts without masks unless everyone gets vaccinated.
</details>


    
  </div>

</>
);

export default Landing;
