import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Paper from '@material-ui/core/Paper';

import { withFirebase } from '../../../components/Firebase';
import {
  enableSpinner,
  disableSpinner,
} from '../../../redux/actions/appActions';
import { URL } from '../../../constants';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = {
  root: {
    width: '100%',
    marginTop: 24,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const Line = (props) => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { width, height } = useWindowDimensions();

  useEffect(() => {}, [width]);

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      if (props.uid === '') return;

      getData(mounted);
    }
    fetchData();

    return () => (mounted = false);
  }, [props.uid]);

  const getData = async (mounted) => {
    try {
      props.enableSpinner('');
      var address = '';
      if (localStorage.getItem('ADDRESS')) {
        address = localStorage.getItem('ADDRESS');
      }

      const snapshot = await props.firebase.db
        .collection('merchants')
        .doc(props.uid)
        .collection('lines')
        .where('location', '==', address)
        .get();
      const users = [];
      snapshot.forEach((doc) => {
        users.push(doc.data());
      });
      mounted && setUsers(users);
      props.disableSpinner();
    } catch (error) {
      console.log(error);
    }
  };

  const sendSms = async (user) => {
    const body = {
      mobile: user.mobile,
      uid: props.uid,
    };

    try {
      props.enableSpinner('');
      const response = await axios.post(`${URL}/resms`, body);
      console.log(response);
      props.disableSpinner();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUser = async (user) => {
    try {
      const snapshot = await props.firebase.db
        .collection('merchants')
        .doc(props.uid)
        .collection('lines')
        .where('mobile', '==', user.mobile)
        .get();
      snapshot.forEach((doc) => {
        doc.ref.delete();
      });
      getData(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const classes = useStyles2;

  return (
    <Paper className="container-table">
      <form className="form">
        {width > 900 ? (
          <Table style={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Mobile Number</TableCell>
                <TableCell>Location</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row">
                      {index + page * rowsPerPage + 1}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.mobile}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => sendSms(row)}
                      >
                        Send SMS
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => deleteUser(row)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={6}
                  count={users.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        ) : (
          <div>
            {users.map((row, index) => (
              <div>
                <div>Name: {row.name}</div>
                <div>
                  Mobile Number:{' '}
                  <b>
                    <span style={{ fontSize: '1em' }}>{row.mobile}</span>
                  </b>
                </div>
                <div>Location: {row.location}</div>
                <div style={{ marginTop: '8px' }}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => sendSms(row)}
                  >
                    Send SMS
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => deleteUser(row)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </form>
    </Paper>
  );
};

export default reduxForm({
  form: 'top_message',
})(
  connect(
    (state) => ({ uid: state.app.uid }),
    (dispatch) => ({
      enableSpinner: (text) => dispatch(enableSpinner(text)),
      disableSpinner: () => dispatch(disableSpinner()),
    }),
  )(withFirebase(Line)),
);
