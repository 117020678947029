import React from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-206624375-1', {
  titleCase: false,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});
ReactGA.pageview(window.location.pathname + window.location.search);

function closeDemo() {
  console.log('tolllo');

  let menudiv = document.getElementById('demoMenu');
  menudiv.style.display = menudiv.style.display == 'block' ? 'none' : 'block';
  let menustatus =
    menudiv.style.display == 'block' ? 'MENU_OPEN' : 'MENU_CLOSE';
  ReactGA.event({
    category: 'DemoMenuActivity',
    action: menustatus,
    label: 'DemoMenuClose',
  });
}

function seeProofDemo() {
  ReactGA.event({
    category: 'DemoClicked',
    action: 'demo_',
    label: 'Cafe Demo - Proof Of Vaccine Demo',
  });

  const povUrl = 'https://www.linehero.com/pov/fXV7K2KH4TaB7';
  window.open(povUrl, '_blank') || window.location.replace(povUrl);
}

const DemoBox = (props) => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '15px',
        backgroundColor: '#1F4358',
        display: 'block',
      }}
      id="demoMenu"
    >
      <div
        style={{
          textAlign: 'left',
          font: 'Roboto',
          fontFamily: 'Roboto',
          fontSize: '1.1rem',
          fontWeight: '900',
          color: '#ffffff',
        }}
      >
        Try Us Out!
      </div>
      <div
        style={{
          textAlign: 'left',
          fontFamily: 'Roboto',
          fontSize: '0.8rem',
          color: '#ffffff',
        }}
      >
        You can use this demo to send an example ticket/QR code to your mobile
        device . This is an example of how eventgoers and customers can use
        LineHero. Have fun!
      </div>
      <button
        style={{
          fontFamily: 'Roboto',
          borderRadius: '0.2rem',
          backgroundColor: '#fff',
          color: '#1F4358',
          textAlign: 'center',
          padding: '0.4rem',
          marginBottom: '0.8rem',
          marginTop: '0.8rem',
          marginLeft: '0.4rem',
          marginRight: '0.4rem',
          fontSize: '1.2rem',
          fontWeight: '900',
          width: '100%',
        }}
        onClick={() => closeDemo()}
      >
        Try it out.
      </button>
      <button
        style={{
          fontFamily: 'Roboto',
          // borderRadius:'0.2rem',
          // borderWidth:'2px',
          // borderColor:'#fff',
          backgroundColor: '#1F4358',
          color: '#fff',
          textAlign: 'center',
          padding: '0.2rem',
          marginBottom: '0.2rem',
          marginTop: '0.2rem',
          marginLeft: '0.2rem',
          marginRight: '0.2rem',
          fontSize: '1.1rem',
          fontWeight: '500',
          width: '100%',
        }}
        onClick={() => seeProofDemo()}
      >
        See an example proof of vaccination
      </button>
    </div>
  );
};

export default DemoBox;
