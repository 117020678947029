import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import Domain from './components/Domain';

const Subdomain = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Company Info</h3>
        <h5>
          This info is what is used as your url handle (example :
          linehero.com/yourcompany){' '}
        </h5>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <Card className="card--not-full-height">
          <CardBody>
            <Domain />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Subdomain;
