import React from 'react';

const Header = (props) => {
  return (
    <div
      style={{
        textAlign: 'center',
        paddingTop: '5px',
        paddingBottom: '5px',
        backgroundColor: '#fff',
      }}
    >
      <img
        src={props.logo}
        alt={props.merchantName}
        style={{ width: '120px', height: 'auto' }}
      />
    </div>
  );
};

export default Header;
