import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import textToImage from 'text-to-image';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import {
  enableSpinner,
  disableSpinner,
  setCompanyName,
} from '../../redux/actions/appActions';
import clsx from 'clsx';
import FirstStep from './components/steps/FirstStep';
import SecondStep from './components/steps/SecondStep';
import ThirdStep from './components/steps/ThirdStep';
import { encrypt, decrypt } from 'react-crypt-gsm';
import { DEVICE_PIXEL_RATIO } from 'ol/has';
import { nanoid } from 'nanoid';
import axios from 'axios';
import { URL } from '../../constants';
import Header from '../Company/components/Header';
import DemoBox from '../Company/components/DemoBox';

//canvas stuff
const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');
// Gradient and pattern are in canvas pixel space, so we adjust for the
// renderer's pixel ratio
const pixelRatio = DEVICE_PIXEL_RATIO;
canvas.width = 8 * pixelRatio;
canvas.height = 8 * pixelRatio;

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      background: '#1F4358',
    },
  },
  completed: {
    '& $line': {
      background: '#1F4358',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#1F4358',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ffff',
    zIndex: 1,
    color: '#1F4358',
    width: '40px',
    height: '40px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #1F4358',
  },
  active: {
    background: '#1F4358',
    color: '#ffff',
  },
  completed: {
    background: '#1F4358',
    color: '#ffff',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const VaccinationProof = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [location, setLocation] = useState('');
  const [companyName, setCompanyName1] = useState('');
  const [ticketNumber, setTicketNumber] = useState('');
  const [ImageData, setImageData] = useState([{}]);
  const [uniqueID, setUniqueID] = useState('');
  const locations = props.locations;

//Demo Company
  let demoCompany = false;
  if (
    companyName == 'cafe' ||
    companyName == 'demo' ||
    companyName == 'voyager' ||
    companyName == 'voyagercc'
  ) {
    demoCompany = true;
  }

  useEffect(() => {
    const pathname = props.location.pathname;
    const companyName = pathname.split('/')[1];
    if (!companyName || companyName === '') return;

    // Creating the bitly link - The unique identifier for every vaccinated person
    setUniqueID(nanoid(13));

    setCompanyName1(companyName);
    props.setCompanyName(companyName);

    let number = props.lineNumber.toString();
    const count = 4 - number.length;
    for (let i = 0; i < count; i++) {
      number = '0' + number;
    }
    setTicketNumber(number);
  }, [props.lineNumber]);

  let steps = [];
  for (let i = 0; i < 3; i++) {
    steps.push(
      <Step key={i}>
        <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
      </Step>,
    );
  }

  const handleSubmit = async (ticketData) => {
    try {
      const date = new Date().toISOString().substring(0, 10);
      await props.firebase.storageRef
        .child(`${props.uid}/lines/${props.lineNumber}`)
        .putString(ticketData, 'data_url');
      const url = await props.firebase.storageRef
        .child(`${props.uid}/lines/${props.lineNumber}`)
        .getDownloadURL();

      // Making the body for the sms API call
      const body = { name, mobile, location, companyName, url };

      let cardImagesURL = [],
        frontPromises = [],
        backPromises = [];

      for (let i = 0; i < ImageData.length; i++) {
        try {
          // Front Card
          let promise = props.firebase.storageRef
            .child(`vaccinationCards/${uniqueID + i + 'front'}`)
            .putString(ImageData[i].front, 'data_url')
            .then(() => {
              let frontURL = props.firebase.storageRef
                .child(`vaccinationCards/${uniqueID + i + 'front'}`)
                .getDownloadURL();
              return frontURL.then((value) => {
                return {
                  cardNumber: i,
                  side: 'front',
                  url: value,
                };
              });
            });
          frontPromises.push(promise);

          // Back card
          promise = props.firebase.storageRef
            .child(`vaccinationCards/${uniqueID + i + 'back'}`)
            .putString(ImageData[i].back, 'data_url')
            .then(() => {
              let backURL = props.firebase.storageRef
                .child(`vaccinationCards/${uniqueID + i + 'back'}`)
                .getDownloadURL();
              return backURL.then((value) => {
                return {
                  cardNumber: i,
                  url: value,
                  side: 'back',
                };
              });
            });
          backPromises.push(promise);
        } catch (err) {
          console.log(err);
        }
      }

      let PromiseResults = await Promise.all([
        ...frontPromises,
        ...backPromises,
        axios.post(`${URL}/sms`, body),
      ]);

      for (let i = 0; i < ImageData.length; i++) {
        let frontURL = PromiseResults.find(
          (data) => data.cardNumber === i && data.side === 'front',
        );
        let backURL = PromiseResults.find(
          (data) => data.cardNumber === i && data.side === 'back',
        );
        cardImagesURL.push({
          frontImageURL: frontURL.url,
          backImageURL: backURL.url,
        });
      }

      //Saving the customers information
      await props.firebase.db
        .collection('vaccinationProof')
        .doc(uniqueID)
        .set({
          name: name,
          mobile: mobile,
          vaccinationCard: cardImagesURL,
          date: date,
        })
        .catch((err) =>
          console.log('Error while saving vaccination Proof', err),
        );
    } catch (error) {
      console.log('error: ', error);
    } finally {
    }
  };

  const onCancel = () => {
    props.history.push({
      pathname: `/fail`,
      state: { name: props.merchantName, uid: props.uid },
    });
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <FirstStep
            topMessage={props.topMessage}
            changeStep={setActiveStep}
            onCancel={onCancel}
            setName={setName}
            setLocation={setLocation}
            setMobile={setMobile}
            name={name}
            location={location}
            mobile={mobile}
            locations={locations}
          />
        );
      case 1:
        return (
          <SecondStep
            onCancel={onCancel}
            ImageData={ImageData}
            setImageData={setImageData}
            changeStep={setActiveStep}
          />
        );
      case 2:
        return (
          <ThirdStep
            name={name}
            handleSubmit={handleSubmit}
            mobile={mobile}
            ticketNumber={ticketNumber}
            proofID={uniqueID}
            logo={props.logo}
            enableSpinner={props.enableSpinner}
            disableSpinner={props.disableSpinner}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <>
      {demoCompany && <DemoBox />}
      {activeStep !== 2 ? (
        <>
          <div
            style={{
              textAlign: 'center',
              paddingTop: '10px',
              paddingBottom: '10px',
              backgroundColor: '#fff',
            }}
          >
            <Header logo={props.logo} merchantName={props.merchantName} />
            {/* <img src={props.logo} style={{ width: '60px', height: 'auto' }} /> */}
          </div>
          <Card style={{ paddingBottom: 0, background: 'white' }}>
            <CardBody style={{ padding: 16, paddingTop: 0 }}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps}
              </Stepper>
              {handleSteps(activeStep)}
            </CardBody>
          </Card>
        </>
      ) : (
        handleSteps(activeStep)
      )}
    </>
  );
};

export default connect(
  (state) => ({
    spinner: state.app.spinner,
    companyName: state.app.companyName,
  }),
  (dispatch) => ({
    enableSpinner: (text) => dispatch(enableSpinner(text)),
    disableSpinner: () => dispatch(disableSpinner()),
    setCompanyName: (name) => dispatch(setCompanyName(name)),
  }),
)(compose(withRouter, withFirebase)(VaccinationProof));
