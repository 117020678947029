import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyDK_XQayqD0LoKzw5jsTEGxJcRKuPweBGM',
  authDomain: 'linehero-09132020.firebaseapp.com',
  databaseURL: 'https://linehero-09132020.firebaseio.com',
  projectId: 'linehero-09132020',
  storageBucket: 'linehero-09132020.appspot.com',
  messagingSenderId: '745679891859',
  appId: '1:745679891859:web:c75399649da7acd94b0c66',
  measurementId: 'G-65VZEG6FWP',
};

class Firebase {
  constructor() {
    !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.storageRef = firebase.storage().ref();
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();
}

export default Firebase;
