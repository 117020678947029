import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../components/Firebase';
import Header from './components/Header';
import Body from './components/Body';
import VaccinationProof from '../VaccinationProof';

const Company = (props) => {
  const [logo, setLogo] = useState('');
  const [locations, setLocations] = useState([]);
  const [topMessage, setTopMessage] = useState('');
  const [available, setAvailable] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [uid, setUid] = useState('');
  const [lineNumber, setLineNumber] = useState(0);
  const [merchantName, setMerchantName] = useState('');
  const [isVaccinationEnabled, setTsVaccinationEnabled] = useState(false);

  useEffect(() => {
    const init = async () => {
      const pathname = props.location.pathname;
      const companyName = pathname.split('/')[1];
      if (!companyName) return;
      setCompanyName(companyName);

      try {
        const snapshot = await props.firebase.db
          .collection('merchants')
          .where('companyName', '==', companyName)
          .get();
        if (snapshot.empty) {
          alert('There is no company with that name');
          props.history.replace('/');
          return;
        }

        setAvailable(true);
        let merchantLogo = '';
        let locations = [];
        let topMessage = '';
        let uid = '';
        let lineNumber;
        let merchantName = '';
        let vaccinationProof = false;
        snapshot.forEach((doc) => {
          merchantLogo = doc.data().merchantLogo;
          locations = doc.data().locations;
          topMessage = doc.data().topMessage;
          merchantName = doc.data().merchantName;
          vaccinationProof = doc.data().enableVaccinationProof;
          if (doc.data().lineNumber) lineNumber = doc.data().lineNumber;
          else lineNumber = 0;
          uid = doc.id;
        });

        setTsVaccinationEnabled(vaccinationProof);
        setLogo(merchantLogo);
        setLocations(locations);
        setTopMessage(topMessage);
        setUid(uid);
        setLineNumber(lineNumber + 1);
        setMerchantName(merchantName);
      } catch (error) {
        console.log(error);
      }
    };

    init();
  }, []);

  return (
    <Container
      style={{
        maxWidth: '720px',
        paddingLeft: 0,
        paddingRight: 0,
        background: 'white',
      }}
    >
      {available ? (
        <>
          {isVaccinationEnabled ? (
            <>
              <VaccinationProof
                locations={locations}
                topMessage={topMessage}
                uid={uid}
                lineNumber={lineNumber}
                merchantName={merchantName}
                logo={logo}
              />
            </>
          ) : (
            <>
              <Header logo={logo} merchantName={merchantName} />
              <Body
                locations={locations}
                topMessage={topMessage}
                uid={uid}
                lineNumber={lineNumber}
                merchantName={merchantName}
                logo={logo}
              />
            </>
          )}
        </>
      ) : null}
    </Container>
  );
};

export default compose(withRouter, withFirebase)(Company);
