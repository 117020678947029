import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
// import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { withFirebase } from '../../../components/Firebase';
import {
  disableSpinner,
  enableSpinner,
} from '../../../redux/actions/appActions';

ReactGA.initialize('UA-206624375-1', {
  titleCase: false,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});
ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA.event({
  category: 'Sign Up',
  action: 'Sign Up Page Loaded',
  label: Date.now(),
});

const INITIAL_STATE = {
  merchantName: '',
  email: '',
  password1: '',
  password2: '',
  showPassword: false,
};

class SignUpForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { email, password1, password2, merchantName } = this.state;
    if (merchantName === '') {
      this.validate('name');
      return;
    }
    if (email === '') {
      this.validate('email');
      return;
    }
    if (password1 === '') {
      this.validate('password');
      return;
    }
    if (password1 !== password2) {
      alert("Password doesn't match");
      return;
    }

    // Google Analytics Event for Registration

    ReactGA.event({
      category: 'Registration',
      action: 'Registration Complete',
      label: merchantName,
    });

    try {
      this.props.enableSpinner('Registering...');
      const authUser =
        await this.props.firebase.doCreateUserWithEmailAndPassword(
          email,
          password1,
        );
      const uid = authUser.user.uid;
      console.log('uid: ', uid);
      await this.props.firebase.db
        .collection('merchants')
        .doc(uid)
        .set({
          merchantName: merchantName,
          merchantLogo: '',
          merchantPhone: '',
          merchantEmail: email,
          merchantTextMessage: `The person with this certificate has attested to the daily building requirements and is permitted to enter ${merchantName} facilites today.
        Please be ready to show this certificate today if requested.
        Important: This certificate is only valid for the calendar date indicated. If you have questions or concerns about facility access with this certificate, please contact ${merchantName} at ${email}`,
          address: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          locations: [],
          merchantId: '',
          sorryMessage: `While we can't allow access, we're here to help.

        Thank you for reporting your response. Your response is confidential and does not impact your relationship with MERCHANT_NAME in any way.
        
        You are not allowed to enter MERCHANT_NAME facilities today because you could not agree to the required daily self attestation. You may enter after:
        
        1. A Minimum of 10 days since symptoms first appeared and you have been symptom free for 72 hours, OR
        
        2. You've completed your 14 day quarantine period if you were in close contact with an individual diagnosed with COVID-19, AND
        
        3. You agree to follow social distancing quidelines while present.`,
          topMessage: `Welcome,
        to protect all of our patrons, and maintain a safe and healthy environment, we required all customers to complete a daily COVID-19 survey before entering our locations.
        We will ask a few questions below which should only take a few minutes.
        You will then receive a digital certificate via SMS text message, which will allow you entry for the day.`,
        });
      this.setState(INITIAL_STATE);
      this.props.history.push('/merchant');
    } catch (error) {
      console.log(error);
    } finally {
      this.props.disableSpinner();
    }
  };

  validate = (param) => {
    alert(`Please fill ${param} please!`);
  };

  render() {
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="merchantName"
              component="input"
              type="text"
              placeholder="Event or Business Name"
              onChange={this.onChange}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email"
              component="input"
              type="email"
              placeholder="Email"
              onChange={this.onChange}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password1"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              onChange={this.onChange}
            />
            <button
              className={`form__form-group-button${
                showPassword ? ' active' : ''
              }`}
              onClick={(e) => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password2"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              onChange={this.onChange}
            />
            <button
              className={`form__form-group-button${
                showPassword ? ' active' : ''
              }`}
              onClick={(e) => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
        </div>
        <button className="btn btn-primary account__btn account__btn--small">
          Sign Up
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'log_in_form',
})(
  compose(
    withRouter,
    withFirebase,
  )(
    connect(
      (state) => ({ spinner: state.app.spinner }),
      (dispatch) => ({
        enableSpinner: (text) => dispatch(enableSpinner(text)),
        disableSpinner: () => dispatch(disableSpinner()),
      }),
    )(SignUpForm),
  ),
);
