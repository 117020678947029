import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withFirebase } from '../../../components/Firebase';

const Message = (props) => {
  const [topMessage, setTopMessage] = useState('');
  const [button, setButton] = useState('Edit');
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      if (props.uid === '') return;

      try {
        const docRef = props.firebase.db.collection('merchants').doc(props.uid);
        const doc = await docRef.get();
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          if (!mounted) return;

          setTopMessage(doc.data().topMessage);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();

    return () => (mounted = false);
  }, [props.uid]);

  const onChange = (event) => {
    setTopMessage(event.target.value);
  };

  const changeButton = () => {
    if (button === 'Edit') {
      setButton('Update');
      setEditable(true);
    } else {
      updateTopMessage();
      setButton('Edit');
      setEditable(false);
    }
  };

  const updateTopMessage = async () => {
    if (topMessage === '') {
      alert('empty');
      return;
    }

    await props.firebase.db.collection('merchants').doc(props.uid).set(
      {
        topMessage: topMessage,
      },
      { merge: true },
    );
  };

  return (
    <form className="form">
      <Col md={12}>
        <Card>
          <CardBody>
            <Row>
              <Col md={12}>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <textarea
                      name="topMessage"
                      type="text"
                      value={topMessage}
                      disabled={!editable}
                      placeholder="Top Message"
                      onChange={onChange}
                    />
                    x1
                  </div>
                </div>
              </Col>
              <Col md={2}>
                <div
                  className="btn btn-primary account__btn account__btn--small"
                  onClick={changeButton}
                >
                  {button}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </form>
  );
};

export default reduxForm({
  form: 'top_message',
})(
  connect((state) => ({
    uid: state.app.uid,
  }))(withFirebase(Message)),
);
