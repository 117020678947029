import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  enableSpinner,
  disableSpinner,
} from '../../../../redux/actions/appActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from '../../../../components/Firebase';
import { Container } from 'reactstrap';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  headerWrapper: {
    background: '#F2F4F2',
    marginLeft: '-16px',
    marginRight: '-16px',
    padding: '15px 50px',
    fontFamily: 'SF Compact Display Medium',
    fontSize: '14px',
  },
  bodyWrapper: {
    height: '100%',
    width: '100%',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'SF Compact Display Medium',
    fontSize: '14px',
    marginTop: '20px',
    padding: '20px',
  },
  card: {
    width: '100%',
    borderRadius: '10px',
    padding: '20px',
  },
  bodyHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}));

const VaccineProofView = (props) => {
  const classes = useStyles();
  const [date, setDate] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [cards, setCards] = useState([]);
  const [showError, setShowError] = useState(false);

  const FetchData = async () => {
    const pathname = props.location.pathname;
    const vaccineID = pathname.split('/')[2];

    try {
      props.enableSpinner('Loading');
      const proof = await props.firebase.db
        .collection('vaccinationProof')
        .doc(vaccineID)
        .get();

      if (!proof.exists) {
        setShowError(true);
        props.disableSpinner();
        setTimeout(() => {
          props.history.replace('/');
          return;
        }, 2000);
      } else {
        setDate(proof.data().date);
        setPhoneNumber(proof.data().mobile);
        setName(proof.data().name);
        setCards(proof.data().vaccinationCard);
        props.disableSpinner();
      }
    } catch (err) {
      console.log(err);
      setShowError(true);
      props.disableSpinner();
      setTimeout(() => {
        props.history.replace('/');
        return;
      }, 3000);
    }
  };

  useEffect(() => {
    FetchData();
  }, []);

  return (
    <Container style={{ maxWidth: '720px', paddingLeft: 0, paddingRight: 0 }}>
      <div className={classes.root}>
        {showError ? (
          <h2
            style={{
              textAlign: 'center',
              paddingTop: '100px',
              paddingBottom: '100px',
            }}
          >
            No Vaccination Proof Available
          </h2>
        ) : (
          <>
            <div className={classes.headerWrapper}>
              <h2 style={{ color: 'black' }}>
                <b>{name}</b>
              </h2>
              <p style={{ color: 'black' }}>Phone: {PhoneNumber}</p>
              <p style={{ color: 'black' }}>Last Verified: - {date}</p>
              <p style={{ color: 'black' }}>
                The below images support that the above individual has uploaded
                their proof of vaccination. Below are the images uploaded by
                them.
              </p>
            </div>

            <div className={classes.bodyWrapper}>
              {cards.map((card, i) => {
                return (
                  <>
                    <div className={classes.bodyHeader}>
                      <div>
                        <p style={{ color: 'black' }}>Card {i + 1}</p>
                      </div>{' '}
                      <div>
                        <p style={{ color: 'black' }}>Uploaded: {date}</p>
                      </div>
                    </div>
                    <div className={classes.card}>
                      <img
                        src={card.backImageURL}
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '10px',
                        }}
                      />
                    </div>
                    <div className={classes.card}>
                      <img
                        src={card.frontImageURL}
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '10px',
                        }}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default connect(
  (state) => ({
    spinner: state.app.spinner,
  }),
  (dispatch) => ({
    enableSpinner: (text) => dispatch(enableSpinner(text)),
    disableSpinner: () => dispatch(disableSpinner()),
  }),
)(compose(withRouter, withFirebase)(VaccineProofView));
