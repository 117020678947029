import {
  ENABLE_SPINNER,
  DISABLE_SPINNER,
  SET_UID,
  SET_COMPANY_NAME,
} from '../actions/appActions';

const initialState = {
  spinner: false,
  text: '',
  uid: '',
  companyName: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ENABLE_SPINNER:
      return { ...state, spinner: true, text: action.payload };
    case DISABLE_SPINNER:
      return { ...state, spinner: false };
    case SET_UID:
      return { ...state, uid: action.payload };
    case SET_COMPANY_NAME:
      return { ...state, companyName: action.payload };
    default:
      return state;
  }
}
