import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import axios from 'axios';
import textToImage from 'text-to-image';
import * as htmlToImage from 'html-to-image';
import { withFirebase } from '../../../components/Firebase';
import {
  enableSpinner,
  disableSpinner,
  setCompanyName,
} from '../../../redux/actions/appActions';
import { URL } from '../../../constants';
import Ticket from '../../../components/ticketComponent/index';
import { DEVICE_PIXEL_RATIO } from 'ol/has';
import DemoBox from './DemoBox';

//canvas stuff
const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');
// Gradient and pattern are in canvas pixel space, so we adjust for the
// renderer's pixel ratio
const pixelRatio = DEVICE_PIXEL_RATIO;
canvas.width = 8 * pixelRatio;
canvas.height = 8 * pixelRatio;

const Body = (props) => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [location, setLocation] = useState('');
  const [companyName, setCompanyName1] = useState('');
  const locations = props.locations;

  let demoCompany = false;
  if (
    companyName == 'cafe' ||
    companyName == 'demo' ||
    companyName == 'voyager' ||
    companyName == 'voyagercc'
  ) {
    demoCompany = true;
  }

  useEffect(() => {
    const pathname = props.location.pathname;
    const companyName = pathname.split('/')[1];
    if (!companyName || companyName === '') return;

    setCompanyName1(companyName);
    props.setCompanyName(companyName);
  }, []);

  const handleChange = (event) => {
    setLocation(event.target.value);
  };

  const handleSubmit = async () => {
    if (name === '') {
      validate('name');
      return;
    }
    if (mobile === '') {
      validate('mobile number');
      return;
    }
    if (location === '') {
      validate('location');
      return;
    }

    try {
      props.enableSpinner('');

      const date = new Date().toISOString().substring(0, 10);

      var node = document.getElementById('ticketDiv');

      // const dataUrl = await htmlToImage.toPng(node);

      let number = props.lineNumber.toString();
      const count = 4 - number.length;
      for (let i = 0; i < count; i++) {
        number = '0' + number;
      }

      //https://firebasestorage.googleapis.com/v0/b/linehero-09132020.appspot.com/o/6vDi13tgtxWGJZHRzBNrdOsF5pC3%2FVoyager.png?alt=media&token=deecf6c8-51a8-4c1a-a0ee-39dfb7528e52
      // Generate a rainbow gradient
      const gradient = (function () {
        const grad = context.createLinearGradient(0, 0, 512 * pixelRatio, 0);
        grad.addColorStop(0, '#00ff87');
        grad.addColorStop(1, '#60efff');
        //  grad.addColorStop(2 / 6, 'yellow');
        // grad.addColorStop(3 / 6, 'green');
        //  grad.addColorStop(4 / 6, 'aqua');
        //  grad.addColorStop(5 / 6, 'blue');
        // grad.addColorStop(1, 'purple');
        return grad;
      })();

      // TEXT Ticket

      const dataUrl = await textToImage.generate(
        `
            ${props.merchantName}
            ${location}
            ---------------------------
            Date : ${date}
            ---------------------------
            Your ticket number:
            #${number}
            `,
        {
          textAlign: 'center',
          maxWidth: 375,
          fontFamily: 'SF Compact Display Black',
          fontSize: 30,
          lineHeight: 30,
          bgColor: gradient,
          textColor: '#313131',
        },
      );

      await props.firebase.storageRef
        .child(`${props.uid}/lines/${props.lineNumber}`)
        .putString(dataUrl, 'data_url');

      const url = await props.firebase.storageRef
        .child(`${props.uid}/lines/${props.lineNumber}`)
        .getDownloadURL();

      const body = { name, mobile, location, companyName, url };

      //Sms API Call
      const res = await axios.post(`${URL}/sms`, body);

      // Sucess page
      props.history.push({
        pathname: `/success`,
        state: {
          url: url,
          name: props.merchantName,
          uid: props.uid,
          lineNumber: res.data.lineNumber,
        },
      });
    } catch (error) {
      console.log('error: ', error);
    } finally {
      props.disableSpinner();
    }
  };

  const validate = (param) => {
    alert(`${param} is empty!`);
  };

  const disagree = () => {
    props.history.push({
      pathname: `/fail`,
      state: { name: props.merchantName, uid: props.uid },
    });
  };

  return (
    <Card style={{ paddingBottom: 0 }}>
      {demoCompany && <DemoBox />}

      <CardBody style={{ padding: 16, paddingTop: 0 }}>
        <div
          style={{
            background: '#F2F4F2',
            marginLeft: '-16px',
            marginRight: '-16px',
            padding: '15px 18px',
            fontFamily: 'SF Compact Display Medium',
            fontSize: '14px',
            color: 'black',
          }}
        >
          {/* Welcome, <br />to protect all of our patrons, and maintain a safe and healthy environment, we required all customers to complete a daily COVID-19 survey before entering our locations.<br /><br />
                    We will ask a few questions below which should only take a few seconds to complete.<br /><br />
                    You will then receive a digital certificate via SMS text message, which will allow you entry for the day. */}
          {props.topMessage}
        </div>
        <div
          style={{
            height: '1px',
            background: 'rgba(0, 0, 0, 0.1)',
            marginBottom: '15px',
            marginLeft: '-16px',
            paddingLeft: '30px',
            marginRight: '-16px',
            paddingRight: '30px',
            color: 'black',
          }}
        />
        <div
          style={{
            fontFamily: 'SF Compact Display Medium',
            fontSize: '16px',
            color: 'black',
          }}
        >
          All form fields are required
          {/* <div id='ticketDiv'>
                        #0023
                    </div> */}
          <form style={{ marginTop: 10 }}>
            <InputBase
              name="name"
              type="text"
              style={{
                border: '1px solid #ced4da',
                borderRadius: 4,
                padding: '6px 12px',
                backgroundColor: '#fff',
              }}
              required={true}
              fullWidth
              placeholder="Name or Initials"
              onChange={(event) => setName(event.target.value)}
            />
            <InputBase
              name="mobile"
              type="phone"
              style={{
                border: '1px solid #ced4da',
                borderRadius: 4,
                padding: '6px 12px',
                backgroundColor: '#fff',
                marginTop: 10,
              }}
              required={true}
              fullWidth
              placeholder="Mobile Number"
              onChange={(event) => setMobile(event.target.value)}
            />
            <FormControl
              variant="outlined"
              style={{ width: '100%', marginTop: 10 }}
            >
              <InputLabel>Location</InputLabel>
              <Select label="Location" value={location} onChange={handleChange}>
                {locations.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.address}
                    onClick={() => setLocation(item.address)}
                  >
                    {item.address}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </div>
        <div
          style={{
            height: '1px',
            background: 'rgba(0, 0, 0, 0.1)',
            marginBottom: '15px',
            marginTop: '25px',
            marginLeft: '-16px',
            paddingLeft: '30px',
            marginRight: '-16px',
            paddingRight: '30px',
          }}
        />
        <div
          id="test"
          style={{
            fontFamily: 'SF Compact Display Medium',
            fontSize: '16px',
            marginBottom: 15,
            color: 'black',
          }}
        >
          Please attest to the following statements.
        </div>
        <div
          style={{
            fontFamily: 'SF Compact Display Regular',
            fontSize: '14px',
            marginBottom: 30,
            color: 'black',
          }}
        >
          1. I agree to wear a face covering per current {props.merchantName}{' '}
          facility guidelines. <br />
          <br />
          2. I have not experienced any of the following symptoms in the last 72
          hours (symptoms related to pre-existing diagnosed health conditions do
          not apply).
          <br />
          &nbsp;&nbsp;&nbsp;&#42;&nbsp;Fever > 100.4F/38C
          <br />
          &nbsp;&nbsp;&nbsp;&#42;&nbsp;Cough
          <br />
          &nbsp;&nbsp;&nbsp;&#42;&nbsp;Shortness of breath
          <br />
          &nbsp;&nbsp;&nbsp;&#42;&nbsp;Malaise/fatigue
          <br />
          &nbsp;&nbsp;&nbsp;&#42;&nbsp;Muscle pain
          <br />
          &nbsp;&nbsp;&nbsp;&#42;&nbsp;Headache
          <br />
          &nbsp;&nbsp;&nbsp;&#42;&nbsp;Diarrhea
          <br />
          &nbsp;&nbsp;&nbsp;&#42;&nbsp;Sore Throat
          <br />
          &nbsp;&nbsp;&nbsp;&#42;&nbsp;Loss of sense of smell or tate
          <br />
          &nbsp;&nbsp;&nbsp;&#42;&nbsp;Chills
          <br />
          &nbsp;&nbsp;&nbsp;&#42;&nbsp;Repeated shaking with chills
          <br />
          <br />
          3. I agree to follow social distancing while at {props.merchantName},
          I agree to follow safety guidelines provided.
          <br />
          <br />
          4. I have not been exposed to a known COVID-19 diagnosed individual
          without wearing appropriate Personal Protective Equipment (per current{' '}
          {props.merchantName} guidelines.)
          <br />
          <br />
          5. I am not currently awaiting results for a COVID-19 test
        </div>

        <Button
          style={{
            background: '#007aff',
            textTransform: 'none',
            color: '#fff',
            fontFamily: 'SF Compact Display Bold',
            fontSize: '20px',
          }}
          onClick={handleSubmit}
          fullWidth
        >
          I agree
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#007aff',
            fontFamily: 'SF Compact Display Bold',
            fontSize: '20px',
            marginTop: 20,
          }}
          onClick={disagree}
          fullWidth
        >
          I disagree
        </Button>

        <Button
          startIcon={<img src={require('../../../assets/copy.png')} />}
          fullWidth
          style={{
            textTransform: 'none',
            fontFamily: 'SF Compact Display Regular',
            fontSize: '12px',
            marginTop: 30,
          }}
        >
          COVID-19 Screener powered by LineHero
        </Button>
      </CardBody>
    </Card>
  );
};

export default connect(
  (state) => ({
    spinner: state.app.spinner,
    companyName: state.app.companyName,
  }),
  (dispatch) => ({
    enableSpinner: (text) => dispatch(enableSpinner(text)),
    disableSpinner: () => dispatch(disableSpinner()),
    setCompanyName: (name) => dispatch(setCompanyName(name)),
  }),
)(compose(withRouter, withFirebase)(Body));
