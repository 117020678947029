import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="69"
      fill="none"
      viewBox="0 0 65 69"
    >
      <path
        fill="#fff"
        d="M40.215 26.373h24.492v14.309H40.215V68.59H25.004V40.682H.383V26.373h24.62V.27h15.212v26.103z"
      ></path>
    </svg>
  );
}

export default Icon;
